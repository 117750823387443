import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { NotificationsDataService, NotificationsDataServiceName } from '../../../DataServices/NotificationsDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { BaseApiDataResponse } from '../../../Models/ApiData';
import { GetPaginatedQueryParameter } from '../../../Models/Commons/PaginatedQuery';
import { NotificationsListResponse } from '../../../Models/Notifications/Notifications';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';

export interface INotificationsService {
    getNotifications: (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ) => Promise<NotificationsListResponse>;

    putReadNotifications: (
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;
}

class NotificationsService extends BaseReduxService implements INotificationsService {
    private readonly notificationsDataService: Lazy<NotificationsDataService>;

    constructor() {
        super();
        this.notificationsDataService = IoC.getLazy<NotificationsDataService>(
            NotificationsDataServiceName
        );
    }

    public getNotifications = async (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ): Promise<NotificationsListResponse> => {
        try {
            const response: NotificationsListResponse = await this.notificationsDataService
                .value()
                .getNotifications(params, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public putReadNotifications = async (
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {

            const response: BaseApiDataResponse = await this.notificationsDataService
                .value()
                .putReadNotifications({ up_to_date_time: new Date().toISOString() }, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

const NotificationsServiceName = 'NotificationsService';
export { NotificationsService, NotificationsServiceName };
