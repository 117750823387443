import './DialogLayout.scss';

import React from 'react';

import { ButtonClassType } from '../../Button/Button';
import { BaseDialog } from '../BaseDialog/BaseDialog';
import { getDialogButton } from './DialogLayoutUtils';

export enum DialogLayoutStyle {
    Default = 'default',
    Danger = 'danger',
}

export interface DialogLayoutProps {
    title: string;
    open: boolean;
    onClose(): void;
    renderContent?(): void;
    style?: DialogLayoutStyle;
    layoutClasses?: object;
    //* actions
    leftAction(): void;
    leftActionLabel: React.ReactNode;
    leftActionDisabled?: boolean;
    rightAction(): void;
    rightActionLabel: React.ReactNode;
    rightActionDisabled?: boolean;
    renderCustomActions?(): void;
}

const DialogLayout: React.FunctionComponent<DialogLayoutProps> = ({
    open,
    renderContent,
    style,
    //layoutClasses: { actions },
    //* buttons
    leftActionLabel,
    leftAction,
    leftActionDisabled,
    rightActionLabel,
    rightAction,
    rightActionDisabled,
    onClose,
    layoutClasses,
    //* custom buttons
    renderCustomActions,
    ...rest
}: DialogLayoutProps) => {
    return (
        <BaseDialog scroll="body" open={open} onClose={onClose} {...rest}>
            <div className="dialog-layout-content">
                {renderContent && renderContent()}
                {!renderCustomActions && (
                    <div className="dialog-layout-content-actions">
                        {getDialogButton({
                            title: leftActionLabel,
                            onClick: leftAction,
                            variant:
                                style === DialogLayoutStyle.Danger
                                    ? ButtonClassType.Danger
                                    : ButtonClassType.Standard,
                            disabled: leftActionDisabled,
                        })}
                        {getDialogButton({
                            title: rightActionLabel,
                            onClick: rightAction,
                            disabled: rightActionDisabled,
                            variant: ButtonClassType.Standard,
                        })}
                    </div>
                )}
                {renderCustomActions && renderCustomActions()}
            </div>
        </BaseDialog>
    );
};

export { DialogLayout };
