import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { LoadingGate } from '../Wrappers/LoadingGate/LoadingGate';
import { usePrivateRouteAccess } from './usePrivateRouteAccess';
import { AppRoutes } from '../../../../Core/App/AppRoutes';
import { Debugger } from '../../../../Core/Errors/Debugger';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface PrivateRouteComponentProps extends RouteComponentProps {
    Page403: React.ComponentType;
}

type MyRouteProps = Omit<RouteProps, 'component' | 'render'>;

export interface PrivateRouteProps extends MyRouteProps {
    component?: React.ComponentType<PrivateRouteComponentProps>;
    render?: (props: PrivateRouteComponentProps) => React.ReactElement;
    Page403: React.ComponentType;
    denyAccess?: boolean;
    location?: any;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
    component: TargetComponent,
    render,
    Page403,
    location,
    denyAccess = false,
    ...rest
}: PrivateRouteProps) => {
    const { isAuthenticated, loading } = usePrivateRouteAccess();

    if (!TargetComponent && !render) {
        throw new Error('Missing target component or render function');
    }

    if (loading) {
        return <LoadingGate showLoader minDelay={0} />;
    }

    return (
        <Route
            {...rest}
            render={(routeProps): React.ReactNode => {
                // if (TargetComponent) {
                //     return <TargetComponent Page403={Page403} {...routeProps} />;
                // }

                if (isAuthenticated && !denyAccess) {
                    if (TargetComponent) {
                        return <TargetComponent Page403={Page403} {...routeProps} />;
                    }
                    if (render) {
                        return render({ Page403, ...routeProps });
                    }
                }

                if (isAuthenticated && denyAccess) {
                    return <Page403 />;
                }

                const redirectUrl = isAuthenticated ? AppRoutes.HOME : AppRoutes.LOGIN;

                Debugger.LogValue('🔥🔥🔥🔥🔥 | PrivateRoute will proceed a redirect ', {
                    from: window.location.pathname,
                    to: redirectUrl,
                    isAuthenticated,
                });
                return <Redirect to={{ pathname: redirectUrl, state: { from: location } }} />;
            }}
        />
    );
};

export { PrivateRoute };
