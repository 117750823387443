import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiIdDataResponse, BaseApiDataResponse } from '../Models/ApiData';
import { ApiUrls } from '../Models/ApiUrls';
import {
    AddCompagnonAttributionRequest,
    CompagnonAttributionResponse,
    UpdateCompagnonAttributionRequest,
} from '../Models/Attribution/Attribution';
import {
    UserDetails,
    UserDetailsApiResponse,
    UserListResponse,
    UserListWithAttributions,
    UserType,
} from '../Models/Users/User';
import { BaseDataService } from './Base/BaseDataService';

export interface IUsersDataService {
    getUsersList: (
        params: {
            type?: UserType;
            is_active?: boolean;
            Page?: number;
            Count?: number;
        },
        cts: XHRRequestCanceler
    ) => Promise<UserListResponse>;

    getUserDetails: (userId: string, cts: XHRRequestCanceler) => Promise<UserDetailsApiResponse>;

    createUser: (body: UserDetails, cts: XHRRequestCanceler) => Promise<ApiIdDataResponse>;

    modifyUser: (
        body: UserDetails,
        id: string,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getUserListWithAttribution: (
        params: { wished_start_day_date: string },
        cts: XHRRequestCanceler
    ) => Promise<UserListWithAttributions>;

    createUserAttribution: (
        compagnonId: string,
        body: AddCompagnonAttributionRequest,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getUserAttribution: (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ) => Promise<CompagnonAttributionResponse>;

    updateUserAttribution: (
        compagnonId: string,
        attributionId: string,
        body: UpdateCompagnonAttributionRequest,
        cts: XHRRequestCanceler
    ) => Promise<CompagnonAttributionResponse>;

    deleteUserAttribution: (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    sendInvitation: (userId: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

class UsersDataService extends BaseDataService implements IUsersDataService {
    public getUsersList = (
        params: {
            type?: UserType;
            is_active?: boolean;
            Page?: number;
            Count?: number;
        },
        cts: XHRRequestCanceler
    ): Promise<UserListResponse> => {
        return this.get<UserListResponse>(ApiUrls.USERS_LIST, cts, { params });
    };

    public getUserDetails = (
        userId: string,
        cts: XHRRequestCanceler
    ): Promise<UserDetailsApiResponse> => {
        return this.get<UserDetailsApiResponse>(ApiUrls.USERS_DETAILS(userId), cts);
    };

    public createUser = (
        body: UserDetails,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<ApiIdDataResponse>(ApiUrls.USERS_CREATE, body, cts);
    };

    public modifyUser = (
        body: UserDetails,
        id: string,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.patch<ApiIdDataResponse>(ApiUrls.USERS_DETAILS(id), body, cts);
    };

    public getUserListWithAttribution = (
        params: { wished_start_day_date: string },
        cts: XHRRequestCanceler
    ): Promise<UserListWithAttributions> => {
        return this.get<UserListWithAttributions>(ApiUrls.USERS_WITH_ATTRIBUTIONS, cts, { params });
    };

    public createUserAttribution = (
        compagnonId: string,
        body: AddCompagnonAttributionRequest,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<ApiIdDataResponse>(
            ApiUrls.USER_CREATE_ATTRIBUTION(compagnonId),
            body,
            cts
        );
    };

    public getUserAttribution = (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ): Promise<CompagnonAttributionResponse> => {
        return this.get<CompagnonAttributionResponse>(
            ApiUrls.USER_GET_ATTRIBUTION(compagnonId, attributionId),
            cts
        );
    };

    public updateUserAttribution = (
        compagnonId: string,
        attributionId: string,
        body: UpdateCompagnonAttributionRequest,
        cts: XHRRequestCanceler
    ): Promise<CompagnonAttributionResponse> => {
        return this.patch<CompagnonAttributionResponse>(
            ApiUrls.USER_UPDATE_ATTRIBUTION(compagnonId, attributionId),
            body,
            cts
        );
    };

    public deleteUserAttribution = (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.delete<BaseApiDataResponse>(
            ApiUrls.USER_DELETE_ATTRIBUTION(compagnonId, attributionId),
            cts
        );
    };

    public sendInvitation = async (
        userId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.post<BaseApiDataResponse>(ApiUrls.USERS_SEND_INVITATION(userId), cts);
    };
}

const UsersDataServiceName = 'UsersDataService';
export { UsersDataService, UsersDataServiceName };
