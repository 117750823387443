export class Constants {
    public static CLIENT_ID = 'Web';
    public static CLIENT_SECRET = 'Ideine';
    private static API_BASE_URL = '__API_HOST__';
    private static API_BASE_URL_DEV = 'https://planniplus-vrd-api-dev.azurewebsites.net';

    public static DEFAULT_NOTIFICATIONS_COUNT_PER_PAGE = 6;
    public static DEFAULT_ITEM_COUNT_PER_PAGE = 15;
    public static isDev = process.env.NODE_ENV === 'development';

    public static FILTER_ALL = 'All';

    public static getRoot = (): string =>
        Constants.isDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL;
}
