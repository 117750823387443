import React from 'react';
import { Button, ButtonClassType } from '../../Button/Button';

export interface DialogButtonType {
    title?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    variant: ButtonClassType;
    disabled?: boolean;
}

export const getDialogButton = ({
    title,
    onClick,
    variant,
    disabled = false,
}: DialogButtonType) => {
    return (
        title && (
            <Button
                type="button"
                variant={variant}
                title={title}
                onClick={onClick}
                disabled={disabled}
                className="dialog-button"
                fullWidth
            />
        )
    );
};
