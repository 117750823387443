import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { AppRoutes } from '../../../Core/App/AppRoutes';
import { PlanningPageLoading } from '../../Pages/Planning/PlanningPageLoading';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';

export class PlanningRoutes {
    private static getPlanningRoute = (props: object): AppRouteType => ({
        id: AppRoutes.PLANNING,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.PLANNING,
            component: PlanningPageLoading,
            ...props,
        },
    });

    public static getPlanningRoutes = (args: object) => [
        PlanningRoutes.getPlanningRoute(args),
    ];
}
