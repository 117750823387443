import { isAfter } from 'date-fns';
import moment, { Moment } from 'moment';

//import { differenceInDays } from 'date-fns/esm';
//import { da } from 'date-fns/locale';
//* import { addMonths } from 'date-fns/esm';

export interface DateRange {
    startDate: Date;
    endDate: Date;
}
export class DateHelpers {
    public static addYear(refDate: Date, nbYear: number): Date {
        return moment(refDate).add(nbYear, 'year').toDate();
    }

    public static addMonth(refDate: Date, nbMonth: number): Date {
        return moment(refDate).add(nbMonth, 'month').toDate();
    }

    public static addWeek(refDate: Date, nbWeek: number): Date {
        return moment(refDate).add(nbWeek, 'week').toDate();
    }

    public static toDayDate(refDate: Date): string {
        return moment(refDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    }

    public static getWeekNumber(date: Date): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    public static getFirstDayOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    public static getLastDayOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    public static getNbDaysInMonth(date: Date): number {
        return moment(date, 'YYYY-MM-DD').daysInMonth();
    }

    public static getNbDaysBeetwen(toDate: Date, fromDate: Date): number {
        const toMomentDate = (date: Date): Moment => {
            return moment(date, 'YYYY-MM-DD');
        };

        const nbDays = toMomentDate(toDate).diff(toMomentDate(fromDate), 'days');
        return nbDays;
        //return differenceInDays(toDate, fromDate); //* work also
    }

    public static getFirstDateOfWeek(dateInWweek: Date): Date {
        const momentDateInWeek = moment(dateInWweek, 'YYYY-MM-DD');
        const firstDateOfWeek = momentDateInWeek.startOf('week');
        return firstDateOfWeek.toDate();
    }

    public static getLastDateOfWeek(dateInWweek: Date): Date {
        const momentDateInWeek = moment(dateInWweek, 'YYYY-MM-DD');
        const lastDateOfWeek = momentDateInWeek.endOf('week');
        return lastDateOfWeek.toDate();
    }

    public static isDateOfWeekend(dateInMweek: Date): boolean {
        if (dateInMweek.getDay() === 6 || dateInMweek.getDay() === 0) {
            return true;
        }
        return false;
    }

    public static isDateRangeOverlaps(range1: DateRange, range2: DateRange): boolean {
        var range1Start: number = range1.startDate.getTime();
        var range1End: number = range1.endDate.getTime();
        var range2Start: number = range2.startDate.getTime();
        var range2End: number = range2.endDate.getTime();

        return (
            (range1Start > range2Start && range1Start < range2End) ||
            (range2Start > range1Start && range2Start < range1End)
        );
    }

    public static getMinDate(date1: Date, date2: Date): Date {
        return isAfter(date1, date2) ? date2 : date1;
    }

    public static getMaxDate(date1: Date, date2: Date): Date {
        return isAfter(date1, date2) ? date1 : date2;
    }

    public static getFirstDayOfTheWeek(dateInWeek: Date): Date {
        return moment(dateInWeek).startOf('week').toDate();
    }

    public static getLastDayOfTheWeek(dateInWeek: Date): Date {
        return moment(dateInWeek).endOf('week').toDate();
    }
}
