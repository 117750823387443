import { UploadData } from '../../../Libs/xhr/ApiClient';
import { XHRRequestConfig } from '../../../Libs/xhr/XHRRequest';
import { XHRRequestCanceler } from '../../../Libs/xhr/XHRRequestCanceler';
import { Debugger } from '../../Errors/Debugger';
import { Lazy } from '../../Helpers/Lazy';
import { BaseApiDataResponse } from '../../Models/ApiData';
import { xhrClient } from '../../Services/Data/Api/ApiService';
import {
    AuthServiceName,
    IAuthService,
} from '../../Services/Data/Authentication/AuthenticationService';
import { IoC } from '../../Services/ServicesContainer';

export class BaseDataService {
    private readonly authService: Lazy<IAuthService>;

    constructor() {
        this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
    }

    public async get<TResult>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig,
        withoutAuth?: boolean
    ): Promise<TResult> {
        if (withoutAuth) {
            return xhrClient.get<TResult>(url, cts, config);
        }
        return xhrClient.get<TResult>(
            url,
            cts,
            config?.noAuth ? undefined : await this.withAuth(url, config)
        );
    }

    public async getRaw<TResult>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig,
        progress?: (value: number) => void
    ): Promise<TResult> {
        return xhrClient.getRaw<TResult>(url, cts, await this.withAuth(url, config), progress);
    }

    public async post<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.post<TResult>(
            url,
            body,
            cts,
            config?.noAuth ? undefined : await this.withAuth(url, config)
        );
    }

    public async put<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.put<TResult>(url, body, cts, await this.withAuth(url, config));
    }

    public async upload<TResult extends BaseApiDataResponse>(
        url: string,
        uploadData: UploadData,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig,
        onProgress?: (value: number) => void
    ): Promise<TResult> {
        return xhrClient.upload<TResult>(
            url,
            uploadData,
            cts,
            config?.noAuth ? undefined : await this.withAuth(url, config),
            onProgress
        );
    }

    public async delete<TResult extends BaseApiDataResponse>(
        url: string,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.delete<TResult>(
            url,
            cts,
            config?.noAuth ? undefined : await this.withAuth(url, config)
        );
    }

    public async patch<TResult extends BaseApiDataResponse>(
        url: string,
        body: object,
        cts?: XHRRequestCanceler,
        config?: XHRRequestConfig
    ): Promise<TResult> {
        return xhrClient.patch<TResult>(
            url,
            body,
            cts,
            config?.noAuth ? undefined : await this.withAuth(url, config)
        );
    }

    //#region  nested

    private async withAuth(url: string, config?: XHRRequestConfig): Promise<XHRRequestConfig> {
        const authToken = await this.getAuthorization(`[ REQUESTED BY ] withAuth for ${url}`);
        Debugger.LogValue('🎆🎆🎆🎆🎆🎆 = withAuth', { authToken, requestedBy: url });

        return {
            ...config,
            headers: {
                ...config?.headers,
                Authorization: authToken,
            },
        };
    }

    protected getAuthorization(reason: string) {
        return this.authService.value().getAuthorization();
    }

    //#endregion
}
