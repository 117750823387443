import { AppRoutes } from '../../../Core/App/AppRoutes';
import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { UserListPageLoading } from '../../Pages/Users/UserListPage/UserListPageLoading';

export class UserRoutes {
    private static getUserListRoute = (props: object): AppRouteType => ({
        id: AppRoutes.USER_LIST,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.USER_LIST,
            component: UserListPageLoading,
            ...props,
        },
    });

    private static getUserCreateRoute = (props: object): AppRouteType => ({
        id: AppRoutes.USER_CREATE,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.USER_CREATE,
            component: UserListPageLoading,
            ...props,
        },
    });

    private static getUserDetailsRoute = (props: object): AppRouteType => ({
        id: AppRoutes.USER_DETAILS,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.USER_DETAILS,
            component: UserListPageLoading,
            ...props,
        },
    });

    public static getUserRoutes = (args: object) => [
        UserRoutes.getUserListRoute(args),
        UserRoutes.getUserCreateRoute(args),
        UserRoutes.getUserDetailsRoute(args),
    ];
}
