import { AppRoutes } from '../../../Core/App/AppRoutes';
import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { CompagonListPageLoading } from '../../Pages/Compagons/CompagonListPage/CompagonListPageLoading';

export class CompagonRoutes {
    private static getCompagonListRoute = (props: object): AppRouteType => ({
        id: AppRoutes.COMPAGNON_LIST,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.COMPAGNON_LIST,
            component: CompagonListPageLoading,
            ...props,
        },
    });

    public static getCompagonRoutes = (args: object) => [CompagonRoutes.getCompagonListRoute(args)];
}
