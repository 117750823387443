import { Route } from 'react-router-dom';

import { LoginPageLoading } from '../../Pages/Auth/Login/LoginPageLoading';

import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { AppRoutes } from '../../../Core/App/AppRoutes';
import { ForgotPasswordPageLoading } from '../../Pages/Auth/ForgotPassword/ForgotPasswordPageLoading';
import { ResetPasswordPageLoading } from '../../Pages/Auth/ResetPassword/ResetPasswordPageLoading';
import { NewPasswordPageLoading } from '../../Pages/Auth/NewPassword/NewPasswordPageLoading';

export class AuthRoutes {
    private static getLoginRoute = (props: object): AppRouteType => ({
        id: AppRoutes.LOGIN,
        render: Route,
        props: {
            exact: true,
            path: AppRoutes.LOGIN,
            component: LoginPageLoading,
            ...props,
        },
    });

    private static getForgotPasswordRoute = (props: object): AppRouteType => ({
        id: AppRoutes.FORGOT_PASSWORD,
        render: Route,
        props: {
            exact: true,
            path: AppRoutes.FORGOT_PASSWORD,
            component: ForgotPasswordPageLoading,
            ...props,
        },
    });

    private static getResetPasswordRoute = (props: object): AppRouteType => ({
        id: AppRoutes.RESET_PASSWORD,
        render: Route,
        props: {
            exact: true,
            path: AppRoutes.RESET_PASSWORD,
            component: ResetPasswordPageLoading,
            ...props,
        },
    });

    private static getNewPasswordRoute = (props: object): AppRouteType => ({
        id: AppRoutes.NEW_PASSWORD,
        render: Route,
        props: {
            exact: true,
            path: AppRoutes.NEW_PASSWORD,
            component: NewPasswordPageLoading,
            ...props,
        },
    });

    public static getAuthRoutes = (args: object) => [
        AuthRoutes.getLoginRoute(args),
        AuthRoutes.getForgotPasswordRoute(args),
        AuthRoutes.getResetPasswordRoute(args),
        AuthRoutes.getNewPasswordRoute(args),
    ];
}
