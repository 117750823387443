import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IMaterielDataService, MaterielDataServiceName } from '../../../DataServices/MaterielDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { ApiIdDataResponse, BaseApiDataResponse } from '../../../Models/ApiData';
import { AddAttributionMateriel, MaterielAttributionResponse } from '../../../Models/Attribution/Attribution';
import {
    MaterielDetailsResponse,
    MaterielListWithAttributions,
    SetMaterielRequest,
} from '../../../Models/Materiel/Materiel';
import { DateUtils } from '../../../Utils/DateUtils';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { I18nServiceName, II18nService } from '../I18n/I18nService';

export interface IMaterielService {
    getMaterielListWithAttribution: (
        params: { startDate: Date },
        cts: XHRRequestCanceler
    ) => Promise<MaterielListWithAttributions>;

    getMateriel: (materielId: string, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    createMateriel: (body: SetMaterielRequest, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    updateMateriel: (materielId: string, body: SetMaterielRequest, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    createMaterielAttribution: (
        materielId: string,
        requestBody: AddAttributionMateriel,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getMaterielAttribution: (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ) => Promise<MaterielAttributionResponse>;

    deleteMaterielAttribution: (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;
}

class MaterielService extends BaseReduxService implements IMaterielService {
    private readonly materielDataService: Lazy<IMaterielDataService>;
    private readonly i18nService: Lazy<II18nService>;

    constructor() {
        super();
        this.materielDataService = IoC.getLazy<IMaterielDataService>(MaterielDataServiceName);
        this.i18nService = IoC.getLazy<II18nService>(I18nServiceName);
    }

    public getMaterielListWithAttribution = async (
        params: { startDate: Date },
        cts: XHRRequestCanceler
    ): Promise<MaterielListWithAttributions> => {
        try {
            const response = await this.materielDataService.value()
                .getMaterielListWithAttribution(
                    {
                        wished_start_day_date: params.startDate
                            ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                                day: DateUtils.formatTwoDigitsNumber(params.startDate?.getDate()),
                                month: DateUtils.formatTwoDigitsNumber(
                                    params.startDate?.getMonth() + 1
                                ),
                                year: params.startDate?.getFullYear(),
                            })
                            : '',
                    },
                    cts
                );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
    public getMateriel = async (
        materielId: string,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        try {
            const response: MaterielDetailsResponse = await this.materielDataService.value()
                .getMateriel(materielId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public createMateriel = async (
        body: SetMaterielRequest,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        try {
            const response: MaterielDetailsResponse = await this.materielDataService.value()
                .createMateriel(body, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public updateMateriel = async (
        materielId: string,
        body: SetMaterielRequest,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        try {
            const response: MaterielDetailsResponse = await this.materielDataService.value().updateMateriel(materielId, body, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public createMaterielAttribution = async (
        materielId: string,
        body: AddAttributionMateriel,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const response: ApiIdDataResponse = await this.materielDataService.value()
                .createMaterielAttribution(materielId, body, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getMaterielAttribution = async (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ): Promise<MaterielAttributionResponse> => {
        try {
            const response: MaterielAttributionResponse = await this.materielDataService.value()
                .getMaterielAttribution(materielId, attributionMaterielId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public deleteMaterielAttribution = async (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.materielDataService.value()
                .deleteMaterielAttribution(materielId, attributionMaterielId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

const MaterielServiceName = 'MaterielService';
export { MaterielService, MaterielServiceName };
