import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { BaseApiDataResponse } from '../Models/ApiData';
import { ApiUrls } from '../Models/ApiUrls';
import { GetPaginatedQueryParameter } from '../Models/Commons/PaginatedQuery';
import { NotificationsListResponse } from '../Models/Notifications/Notifications';
import { BaseDataService } from './Base/BaseDataService';

export interface INotificationsDataService {
    getNotifications: (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ) => Promise<NotificationsListResponse>;

    putReadNotifications: (
        params: { up_to_date_time: string },
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;
}

class NotificationsDataService extends BaseDataService implements INotificationsDataService {
    public getNotifications = (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ): Promise<NotificationsListResponse> => {
        return this.get<NotificationsListResponse>(ApiUrls.GET_NOTIFICATIONS, cts, { params });
    };

    public putReadNotifications = (
        params: { up_to_date_time: string },
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.put<BaseApiDataResponse>(ApiUrls.PUT_NOTIFICATIONS, params, cts);
    }
}

const NotificationsDataServiceName: string = 'NotificationsDataService';

export { NotificationsDataService, NotificationsDataServiceName };
