import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { AlertsDataService, AlertsDataServiceName } from '../../../DataServices/AlertsDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { GetPaginatedQueryParameter } from '../../../Models/Commons/PaginatedQuery';
import { AlertsListResponse } from '../../../Models/Notifications/Alerts';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';

export interface IAlertsService {
    getAlerts: (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ) => Promise<AlertsListResponse>;
}

class AlertsService extends BaseReduxService implements IAlertsService {
    private readonly alertsDataService: Lazy<AlertsDataService>;

    constructor() {
        super();
        this.alertsDataService = IoC.getLazy<AlertsDataService>(
            AlertsDataServiceName
        );
    }

    public getAlerts = async (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ): Promise<AlertsListResponse> => {
        try {
            const response = await this.alertsDataService
                .value()
                .getAlerts(params, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

const AlertsServiceName = 'AlertsService';
export { AlertsService, AlertsServiceName };
