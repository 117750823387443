import { ApiDataResponse } from '../ApiData';

export enum GrantType {
    Password = 'Password',
}

export interface LoginRequest {
    email: string;
    password: string;
    grant_type: GrantType;
    client_id: string;
    client_secret: string;
}

export interface RefreshRequest {
    refresh_token: string;
    client_id: string;
    client_secret: string;
}

export enum TokenType {
    Bearer,
}

export interface ResetPassword {
    email: string;
}

export interface ResetPasswordAuthenticate {
    email: string;
    new_password: string,
    client_id: string;
    client_secret: string;
}

export interface AuthenticationResult {
    access_token: string;
    refresh_token: string;
    expires_in: number; // seconds, from when the token was acquired
    token_type: string;
    expiration_date: string; // ISO string
}

export type AuthenticationResponse = ApiDataResponse<AuthenticationResult>;
