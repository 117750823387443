import React, { HTMLAttributes } from 'react';

import icHeaderRightArrowBlack from '../../../assets/icons/header/ic_header_arrow_black.svg';
import icHeaderLogout from '../../../assets/icons/header/ic_header_logout.svg';
import icHeaderNotification from '../../../assets/icons/header/ic_header_user_black.svg';
import icAddAttributionWhite from '../../../assets/icons/ic_add_attribution_white.svg';
import icAddBlue from '../../../assets/icons/ic_add_blue.svg';
import icAddEquipment from '../../../assets/icons/ic_add_equipment.svg';
import icAddSite from '../../../assets/icons/ic_add_site.svg';
import icAddUser from '../../../assets/icons/ic_add_user.svg';
import icAddWhite from '../../../assets/icons/ic_addWhite.svg';
import icAlert from '../../../assets/icons/ic_alert.svg';
import icAlertBlue from '../../../assets/icons/ic_alert_blue.svg';
import icArrowLeftBlue from '../../../assets/icons/ic_arrow_left_blue.svg';
import icArrowRightBlue from '../../../assets/icons/ic_arrow_right_blue.svg';
import icArrowRightWhite from '../../../assets/icons/ic_arrowRightWhite.svg';
import icAttentionGrey from '../../../assets/icons/ic_attentionGrey.png';
import icCalendarRed from '../../../assets/icons/ic_calendar_red.svg';
import icCalendarBlue from '../../../assets/icons/ic_calendarBlue.svg';
import icCheckbox from '../../../assets/icons/ic_checkbox.svg';
import icChecked from '../../../assets/icons/ic_checked.svg';
import icCheckmark from '../../../assets/icons/ic_checkmark.svg';
import icClockBlue from '../../../assets/icons/ic_clock.png';
import close from '../../../assets/icons/ic_close.svg';
import icCloseBlack from '../../../assets/icons/ic_close_black.svg';
import icDeleteWhite from '../../../assets/icons/ic_deleteWhite.svg';
import icDocumentRed from '../../../assets/icons/ic_document_red.svg';
import icNoResult from '../../../assets/icons/ic_no_result.svg';
import icNotifications from '../../../assets/icons/ic_notifications.svg';
import icNotificationsBlue from '../../../assets/icons/ic_notificationsBlue.svg';
import icPenBlue from '../../../assets/icons/ic_penBlue.svg';
import icDotGreen from '../../../assets/icons/ic_rdv_dot_green.svg';
import icRendezVousGreen from '../../../assets/icons/ic_rdv_green.svg';
import icDeleteRoundBlue from '../../../assets/icons/ic_removeRoundBlue.svg';
import search from '../../../assets/icons/ic_search.svg';
import selectArrowDownBlue from '../../../assets/icons/ic_selectArrowDownBlue.svg';
import selectArrowDownWhite from '../../../assets/icons/ic_selectArrowDownWhite.svg';
import icStarGreen from '../../../assets/icons/ic_star_green.svg';
import icStarWhite from '../../../assets/icons/ic_star_white.svg';
import icStarGreenBackground from '../../../assets/icons/ic_starGreenBackground.svg';
import itemMarkerBlue from '../../../assets/icons/ItemMarker/ic_itemMarkerBlue.svg';
import itemMarkerDarkBlue from '../../../assets/icons/ItemMarker/ic_itemMarkerDarkBlue.svg';
import itemMarkerGreen from '../../../assets/icons/ItemMarker/ic_itemMarkerGreen.svg';
import itemMarkerOrange from '../../../assets/icons/ItemMarker/ic_itemMarkerOrange.svg';
import messageBlack from '../../../assets/icons/message/ic_messageBlack.svg';
import messageGray from '../../../assets/icons/message/ic_messageGray.svg';
import iconPaginationEllipsis from '../../../assets/icons/PaginationIcons/ic_pagination_ellipsis.svg';
import iconPaginationNext from '../../../assets/icons/PaginationIcons/ic_pagination_next.svg';
import iconPaginationPrevious from '../../../assets/icons/PaginationIcons/ic_pagination_previous.svg';

//#region IndividualIcons
//#endregion

//#region CustomPaginationIcons
//#endregion

//#region SelectIcons
//#endregion

//#region MessageIcons
//#endregion

//#region ItemMarker
//#endregion

export interface IconProps {
    src: string;
    name?: string;
    className?: string;
    inlineStyle?: HTMLAttributes<HTMLImageElement>;
}

export const Icon = ({ src, name, className, inlineStyle }: IconProps): React.ReactElement => {
    return <img className={className} src={src} alt={name} style={inlineStyle} />;
};

//#endregion

export class Icons {
    // #region IndividualIcons
    public static IconHeaderRightArrowBlack = Icon({
        src: icHeaderRightArrowBlack,
        name: 'iconRightArrowBlack',
    });
    public static IconHeaderLogout = Icon({ src: icHeaderLogout, name: 'icon logout' });
    public static IconHeaderNotification = Icon({
        src: icHeaderNotification,
        name: 'icon notification',
    });
    public static IconCloseBlack = Icon({ src: icCloseBlack, name: 'icon close black' });

    public static IconCheckbox = Icon({ src: icCheckbox, name: 'icon check' });
    public static IconCheckboxChecked = Icon({ src: icChecked, name: 'icon checked' });
    public static IconCheckmark = Icon({ src: icCheckmark, name: 'icon checkmark' });

    public static IconClose = Icon({ src: close, name: 'close icon' });
    public static IconSearch = Icon({ src: search, name: 'search icon' });
    public static IconAddSite = Icon({ src: icAddSite, name: 'add site icon' });
    public static IconNoResult = Icon({ src: icNoResult, name: 'no result icon' });
    public static IconEndRow = Icon({ src: iconPaginationNext, name: 'end row icon' });
    public static IconAddUser = Icon({ src: icAddUser, name: 'add user icon' });
    public static IconArrowRightWhite = Icon({ src: icArrowRightWhite, name: 'arrow right' });
    public static IconPenBlue = Icon({ src: icPenBlue, name: 'blue pen' });
    public static IconAddWhite = Icon({ src: icAddWhite, name: 'add' });
    public static IconStarGreenBackground = Icon({ src: icStarGreenBackground, name: 'star' });
    public static IconCalendarBlue = Icon({ src: icCalendarBlue, name: 'calendar' });
    public static IconClockBlue = Icon({ src: icClockBlue, name: 'clock' });
    public static IconDeleteRoundBlue = Icon({ src: icDeleteRoundBlue, name: 'delete' });
    public static IconAttentionGrey = Icon({ src: icAttentionGrey, name: 'attention' });

    public static IconAddAttributionWhite = Icon({
        src: icAddAttributionWhite,
        name: 'add attribution',
    });

    public static IconNotifications = Icon({ src: icNotifications, name: 'notification default' });
    public static IconNotificationsBlue = Icon({
        src: icNotificationsBlue,
        name: 'notification opened',
    });
    public static IconAlert = Icon({ src: icAlert, name: 'alert default' });
    public static IconAlertBlue = Icon({ src: icAlertBlue, name: 'alert opened' });
    public static IconCalendarRed = Icon({ src: icCalendarRed, name: 'alert type calendar' });
    public static IconDocumentRed = Icon({ src: icDocumentRed, name: 'alert type document' });

    //#endregion

    //#region SelectIcons
    public static IconSelectBlue = Icon({ src: selectArrowDownBlue, name: 'arrow down icon' });
    public static IconSelectWhite = Icon({
        src: selectArrowDownWhite,
        name: 'arrow down icon white',
    });

    public static IconAddBlue = Icon({ src: icAddBlue, name: 'add blue' });
    public static IconArrowLeftBlue = Icon({ src: icArrowLeftBlue, name: 'arrow left blue' });
    public static IconArrowRightlue = Icon({ src: icArrowRightBlue, name: 'arrow right blue' });
    public static IconRendezVousGreen = Icon({ src: icRendezVousGreen, name: 'rdv green' });

    public static IconStarWhite = Icon({ src: icStarWhite, name: 'start white' });
    public static IconStarGreen = Icon({ src: icStarGreen, name: 'start green' });
    public static IconAddEquipment = Icon({ src: icAddEquipment, name: 'add equipment' });

    public static IconDotGreen = Icon({ src: icDotGreen, name: 'dot green' });
    public static IconDeleteWhite = Icon({ src: icDeleteWhite, name: 'delete' });

    //#endregion

    //#region PaginationIcons
    public static IconPaginationPrevious = Icon({
        src: iconPaginationPrevious,
        name: 'pagination previous icon',
    });
    public static IconPaginationNext = Icon({
        src: iconPaginationNext,
        name: 'pagination next icon',
    });
    public static IconPaginationEllipsis = Icon({
        src: iconPaginationEllipsis,
        name: 'pagination ellipsis icon',
    });
    //#endregion

    //#region MessageIcons
    public static IconMessageGray = Icon({
        src: messageGray,
        name: 'message gray',
    });
    public static IconMessageBlack = Icon({
        src: messageBlack,
        name: 'message black',
    });
    //#endregion

    //#region ItemMarker
    public static IconItemMarkerOrange = Icon({
        src: itemMarkerOrange,
        name: 'item marker',
    });
    public static IconItemMarkerBlue = Icon({
        src: itemMarkerBlue,
        name: 'item marker',
    });
    public static IconItemMarkerDarkBlue = Icon({
        src: itemMarkerDarkBlue,
        name: 'item marker',
    });
    public static IconItemMarkerGreen = Icon({
        src: itemMarkerGreen,
        name: 'item marker',
    });
    //#endregion
}

export default Icon;
