import { history } from '../../Configs/history/history';
import { AppRoutes } from '../../Core/App/AppRoutes';
import { INavigationService } from '../../Core/Services/Interfaces/INavigationService';

//* Generic detail route
const getDetailsRoute = (id: string, detailsRoute: string): string =>
    `${detailsRoute}`.replace(':id', id);

export class NavigationService implements INavigationService {
    getHomeLink = AppRoutes.HOME;

    goTo = (route: string, replace?: boolean): void => {
        if (replace) {
            history.replace(route);
        } else {
            history.push(route);
        }
    };

    goBack = () => history.goBack();

    goToHome = (): void => history.push(AppRoutes.HOME);
    goToLogin = (): void => history.push(AppRoutes.LOGIN);

    //* SITES
    getSiteDetails = (id: string) => getDetailsRoute(id, AppRoutes.SITE_DETAILS);
    goToSiteDetails = (id: string) => history.push(getDetailsRoute(id, AppRoutes.SITE_DETAILS));
    goToSiteDetailsPlanning = (id: string) =>
        history.push(getDetailsRoute(id, AppRoutes.SITE_DETAILS_PLANNING));

    getSiteList = () => AppRoutes.SITE_LIST;
    goToSiteList = () => history.push(AppRoutes.SITE_LIST);
    goToSiteCreate = () => history.push(AppRoutes.SITE_CREATE);

    //* USERS
    getUserDetails = (id: string) => getDetailsRoute(id, AppRoutes.USER_DETAILS);
    goToUserCreate = () => history.push(AppRoutes.USER_CREATE);
    goToUserList = () => history.push(AppRoutes.USER_LIST);

    //* EQUIPMENTS
    goToEquipmentDetails = (id: string) =>
        history.push(getDetailsRoute(id, AppRoutes.EQUIPMENT_DETAILS));
    goToEquipmentCreate = () => history.push(AppRoutes.EQUIPMENT_CREATE);
    goToEquipmentList = () => history.push(AppRoutes.EQUIPMENT_LIST);
}
