import React from 'react';
import { ReactElement } from 'react';
import ReactDOM from 'react-dom';

import {
    IDialogService,
    DialogAction,
    DialogStyle,
} from '../../Core/Services/Interfaces/IDialogService';
import { ConfirmDialog } from '../Components/Views/Dialogs/ConfirmDialog/ConfirmDialog';
import { DialogLayoutStyle } from '../Components/Views/Dialogs/DialogLayout/DialogLayout';

export const DialogContainerId: string = 'CustomDialogContainerId';

export class DialogService implements IDialogService {
    private dialogContainer: Element | null = null;

    private initializeDialogContainerIfNeeded = (): void => {
        if (this.dialogContainer === null) {
            this.dialogContainer = document.getElementById(DialogContainerId);
        }
    };

    public showDialog = (
        title: string,
        content: string,
        leftActionTitle: string,
        rightActionTitle: string,
        leftAction: DialogAction,
        rightAction: DialogAction,
        onClosed: DialogAction,
        style?: DialogStyle
    ): void => {
        this.internalShowDialog(
            <ConfirmDialog
                open={true}
                title={title}
                message={content}
                leftActionLabel={leftActionTitle}
                leftAction={() => this.closeDialog(leftAction)}
                rightActionLabel={rightActionTitle}
                rightAction={() => this.closeDialog(rightAction)}
                onClose={() => this.closeDialog(onClosed)}
                style={
                    style === DialogStyle.Danger
                        ? DialogLayoutStyle.Danger
                        : DialogLayoutStyle.Default
                }
            />
        );
    };

    //#region OPEN AND CLOSE

    private internalShowDialog = (content: ReactElement) => {
        this.initializeDialogContainerIfNeeded();
        ReactDOM.render(content, this.dialogContainer);
    };

    private closeDialog = (actionToDo: () => void) => {
        if (this.dialogContainer != null && ReactDOM.unmountComponentAtNode(this.dialogContainer)) {
            actionToDo();
        }
    };

    //#endregion
}
