import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IPlanningDataService, PlanningDataService } from '../../../DataServices/PlanningDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { PlanningChantiersResponse } from '../../../Models/Planning/PlanningChantiersModels';
import { PlanningCompagnonsResponse } from '../../../Models/Planning/PlanningCompagnonsModels';
import { PlanningMaterielsResponse } from '../../../Models/Planning/PlanningMaterielsModels';
import { PlanningRequestParams } from '../../../Models/Planning/PlanningRequestParams';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';

export interface IPlanningService {
    getPlanningChantiers: (planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningChantiersResponse>;

    getPlanningCompagnons: (planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningCompagnonsResponse>;

    getPlanningEquipments: (planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningMaterielsResponse>
}

export class PlanningService extends BaseReduxService implements IPlanningService {

    public static NAME = "PlanningService"

    private readonly planningChantierDataService: Lazy<IPlanningDataService>

    constructor() {
        super();
        this.planningChantierDataService = IoC.getLazy<IPlanningDataService>(
            PlanningDataService.NAME
        );
    }

    public getPlanningChantiers(planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ): Promise<PlanningChantiersResponse> {
        return this.planningChantierDataService.value().getPlanningChantiers(planningRequestParams, cts);
    }

    public getPlanningCompagnons(planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ): Promise<PlanningCompagnonsResponse> {
        return this.planningChantierDataService.value().getPlanningCompagnons(planningRequestParams, cts);
    }

    public getPlanningEquipments(planningRequestParams: PlanningRequestParams, cts?: XHRRequestCanceler
    ): Promise<PlanningMaterielsResponse> {
        return this.planningChantierDataService.value().getPlanningEquipments(planningRequestParams, cts);
    }

}