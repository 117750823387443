import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiIdDataResponse, BaseApiDataResponse } from '../Models/ApiData';
import { ApiUrls } from '../Models/ApiUrls';
import { GetPaginatedQueryParameter } from '../Models/Commons/PaginatedQuery';
import { DocumentListApiResponse, DocumentStatus, ChangeDocumentApiResponse } from '../Models/Sites/Documents';
import {
    CreateOrUpdatePhaseRequest,
    CreateOrUpdateRDVRequest,
    EventListApiResponse,
    GetPhasesQueryParameters,
    PhaseApiDataResponse,
    PhaseListApiDataResponse,
    PlanificationRdvApiResponse,
} from '../Models/Sites/Planification';
import { SiteDetails, SiteDetailsResponse, SiteListResponse } from '../Models/Sites/Sites';
import { BaseDataService } from './Base/BaseDataService';

export interface ISitesDataService {
    getSiteList: (
        cts: XHRRequestCanceler,
        params?: GetPaginatedQueryParameter
    ) => Promise<SiteListResponse>;

    createSite: (body: any, cts: XHRRequestCanceler) => Promise<ApiIdDataResponse>;

    getSiteDetails: (siteId: string, cts: XHRRequestCanceler) => Promise<SiteDetailsResponse>;

    editSite: (siteId: string, body: any, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;

    getDocuments: (siteId: string, cts: XHRRequestCanceler) => Promise<DocumentListApiResponse>;

    setDocuments: (
        documents: Array<string>,
        siteId: string,
        cts: XHRRequestCanceler
    ) => Promise<ChangeDocumentApiResponse>;

    editDocument: (
        params: { chantierId: string; documentId: string },
        body: {
            state: DocumentStatus;
            start_day_date: string | undefined;
            end_day_date: string | undefined;
            comment: string;
        },
        cts: XHRRequestCanceler
    ) => Promise<ChangeDocumentApiResponse>;

    getPlannificationEventList: (
        chantierId: string,
        cts: XHRRequestCanceler
    ) => Promise<EventListApiResponse>;

    createRdv: (
        chantierId: string,
        body: CreateOrUpdateRDVRequest,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getRdv: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<PlanificationRdvApiResponse>;

    editRdv: (
        chantierId: string,
        eventId: string,
        body: CreateOrUpdateRDVRequest,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    deleteRdv: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<PlanificationRdvApiResponse>;

    createPhase: (
        chantierId: string,
        body: CreateOrUpdatePhaseRequest,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getPhase: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<PhaseApiDataResponse>;

    updatePhase: (
        chantierId: string,
        phaseId: string,
        body: CreateOrUpdatePhaseRequest,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    deletePhase: (
        chantierId: string,
        phaseId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    getPhaseList: (
        params: GetPhasesQueryParameters,
        cts: XHRRequestCanceler
    ) => Promise<PhaseListApiDataResponse>;
}

class SitesDataService extends BaseDataService implements ISitesDataService {
    public getSiteList = (
        cts: XHRRequestCanceler,
        params?: GetPaginatedQueryParameter
    ): Promise<SiteListResponse> => {
        return this.get<SiteListResponse>(ApiUrls.SITE_LIST, cts, {
            params,
        });
    };

    public createSite = (
        body: SiteDetails,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<ApiIdDataResponse>(ApiUrls.SITE_CREATE, body, cts);
    };

    public getSiteDetails = (id: string, cts: XHRRequestCanceler): Promise<SiteDetailsResponse> => {
        return this.get<SiteDetailsResponse>(ApiUrls.SITE_DETAILS(id), cts);
    };

    public editSite = (
        siteId: string,
        body: SiteDetails,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.patch(ApiUrls.SITE_EDIT(siteId), body, cts);
    };

    public getDocuments = (
        siteId: string,
        cts: XHRRequestCanceler
    ): Promise<DocumentListApiResponse> => {
        return this.get<DocumentListApiResponse>(ApiUrls.SITE_DOCUMENT_LIST(siteId), cts);
    };

    public setDocuments = (
        documents: Array<string>,
        siteId: string,
        cts: XHRRequestCanceler
    ): Promise<ChangeDocumentApiResponse> => {
        return this.patch<ChangeDocumentApiResponse>(
            ApiUrls.SITE_DOCUMENT_LIST(siteId),
            {
                document_type_list: documents,
            },
            cts
        );
    };

    public editDocument = (
        params: { chantierId: string; documentId: string },
        body: {
            state: DocumentStatus;
            start_day_date: string | undefined;
            end_day_date: string | undefined;
            comment: string;
        },
        cts: XHRRequestCanceler
    ): Promise<ChangeDocumentApiResponse> => {
        return this.patch<ChangeDocumentApiResponse>(
            ApiUrls.SITE_DOCUMENT_EDIT(params.chantierId, params.documentId),
            body,
            cts
        );
    };

    public getPlannificationEventList = (
        chantierId: string,
        cts: XHRRequestCanceler
    ): Promise<EventListApiResponse> => {
        return this.get<EventListApiResponse>(
            ApiUrls.SITE_PLANIFICATION_EVENT_LIST(chantierId),
            cts
        );
    };

    public createRdv = (
        chantierId: string,
        body: CreateOrUpdateRDVRequest,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<ApiIdDataResponse>(
            ApiUrls.SITE_PLANIFICATION_CREATE_RDV(chantierId),
            body,
            cts
        );
    };

    public getRdv = (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PlanificationRdvApiResponse> => {
        return this.get<PlanificationRdvApiResponse>(
            ApiUrls.SITE_PLANIFICATION_GET_RDV(chantierId, eventId),
            cts
        );
    };

    public editRdv = (
        chantierId: string,
        eventId: string,
        body: CreateOrUpdateRDVRequest,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.patch<BaseApiDataResponse>(
            ApiUrls.SITE_PLANIFICATION_EDIT_RDV(chantierId, eventId),
            body,
            cts
        );
    };

    public deleteRdv = (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PlanificationRdvApiResponse> => {
        return this.delete<PlanificationRdvApiResponse>(
            ApiUrls.SITE_PLANIFICATION_DELETE_RDV(chantierId, eventId),
            cts
        );
    };

    public createPhase = (
        chantierId: string,
        body: CreateOrUpdatePhaseRequest,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<ApiIdDataResponse>(
            ApiUrls.SITE_PLANIFICATION_CREATE_PHASE(chantierId),
            body,
            cts
        );
    };

    public getPhase = (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PhaseApiDataResponse> => {
        return this.get<PhaseApiDataResponse>(
            ApiUrls.SITE_PLANIFICATION_GET_PHASE(chantierId, eventId),
            cts
        );
    };

    public updatePhase = (
        chantierId: string,
        phaseId: string,
        body: CreateOrUpdatePhaseRequest,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.patch<ApiIdDataResponse>(
            ApiUrls.SITE_PLANIFICATION_UPDATE_PHASE(chantierId, phaseId),
            body,
            cts
        );
    };

    public deletePhase = (
        chantierId: string,
        phaseId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.delete<BaseApiDataResponse>(
            ApiUrls.SITE_PLANIFICATION_DELETE_PHASE(chantierId, phaseId),
            cts
        );
    };

    public getPhaseList = (
        params: GetPhasesQueryParameters,
        cts: XHRRequestCanceler
    ): Promise<PhaseListApiDataResponse> => {
        return this.get<PhaseListApiDataResponse>(ApiUrls.GET_PHASE_LIST, cts, { params });
    };
}

const SitesDataServiceName = 'SitesDataService';
export { SitesDataService, SitesDataServiceName };
