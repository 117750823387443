import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { AppRouteType } from './AppRouteType';
import { history } from '../../../../Configs/history/history';

export interface AppRouterProps {
    defaultRoote: string;
    routes: Array<AppRouteType>;
}

const AppRouter: React.FunctionComponent<AppRouterProps> = ({
    defaultRoote,
    routes,
}: AppRouterProps) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                {routes.map(({ id, render: Render, props }) => (
                    <Render key={id} {...props} />
                ))}
                <Redirect to={defaultRoote} />
            </Switch>
        </ConnectedRouter>
    );
};

export { AppRouter };
