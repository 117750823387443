import { HttpStatusCode } from './HttpStatusCode';
export interface CustomHttpErrors {
    isApiError: boolean;
    httpStatusCode: HttpStatusCode;
    errorCode: string;
    errorMessage: string;
    checkError: boolean;
}

export interface IErrorHandler {
    handle: (exception: any, customErrorHandler?: (exception: any) => boolean) => Promise<boolean>;
}

class ErrorHandler implements IErrorHandler {
    ERROR_HANDLED: boolean = true;
    ERROR_NOT_HANDLED: boolean = false;

    public handle = async (
        error: any,
        customErrorHandler?: (error: any) => boolean
    ): Promise<boolean> => {
        return Promise.resolve(this.ERROR_HANDLED);
    };

    public static getCustomHttpErrors = (axiosHttpError: any): CustomHttpErrors => {
        const { config, response } = axiosHttpError;

        //* Error can be trigged by axios interceptor or
        //* Rejected by apiClient and logged to user as a wrapped error
        //* in this case config, response are in object called errorResponse
        const checkError = config ? config.checkError ?? true : false;

        const {
            data: { error_code = '', error_message = '' },
            status,
        } = response || { data: { error_code: '', error_message: '' } };

        const httpStatusCode = status as HttpStatusCode;

        return {
            httpStatusCode,
            errorCode: error_code,
            isApiError: !!httpStatusCode,
            errorMessage: error_message,
            checkError,
        };
    };
}
const ErrorHandlerName = 'ErrorHandler';

export { ErrorHandler, ErrorHandlerName };
