import { LocaleMessageState } from "../../Core/Services/Data/I18n/typings/LocaleMessageState";
import { SUPPORTED_LOCALES } from "../../Core/Services/Data/I18n/SetupIntl";
import { fr } from "../../Core/Locales/fr";

const I18n: LocaleMessageState = {
    defaultLocale: 'fr',
    locale: 'fr',
    supported_locales: SUPPORTED_LOCALES,
    messages: { ...fr },
};

export const InitialState = { I18n };
