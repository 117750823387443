export enum AppRoutes {
    HOME = '/planning',

    //* Auth
    LOGIN = '/login',
    FORGOT_PASSWORD = '/password/forgot',
    RESET_PASSWORD = '/password/reset',
    NEW_PASSWORD = '/password/new',

    //* COMPAGNONS
    PLANNING = '/planning',

    //* SITE
    SITE_LIST = '/sites',
    SITE_CREATE = '/sites/create',
    SITE_DETAILS = '/sites/:id',
    SITE_DETAILS_PLANNING = '/sites/:id#planning',

    //* COMPAGNONS
    COMPAGNON_LIST = '/compagons',

    //* EQUIPMENTS
    EQUIPMENT_LIST = '/equipments',
    EQUIPMENT_CREATE = '/equipments/create',
    EQUIPMENT_DETAILS = '/equipments/:id',

    //* USERS
    USER_LIST = '/users',
    USER_CREATE = '/users/create',
    USER_DETAILS = '/users/:id',
}
