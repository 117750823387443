import { CustomHttpErrors, ErrorHandler } from '../../Libs/xhr/ErrorHandler';
import { HttpStatusCode } from '../../Libs/xhr/HttpStatusCode';
import { AppRoutes } from '../App/AppRoutes';
import { Lazy } from '../Helpers/Lazy';
import { AuthServiceName, IAuthService } from '../Services/Data/Authentication/AuthenticationService';
import { II18nService } from '../Services/Data/I18n/I18nService';
import { getNavigationService } from '../Services/Interfaces/INavigationService';
import { getToastService } from '../Services/Interfaces/IToastService';
import { ILogService } from '../Services/PPLusLogService';
import { IoC } from '../Services/ServicesContainer';
import { Debugger } from './Debugger';

export class PPLusErrorHandler extends ErrorHandler {
    //* Services
    private readonly i18nService: II18nService;
    private readonly logService: ILogService;
    private readonly authService: Lazy<IAuthService>;

    constructor(i18nService: II18nService, logService: ILogService) {
        super();
        this.i18nService = i18nService;
        this.logService = logService;
        this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
    }

    public handle = async (error: any): Promise<boolean> => {
        const customHttpErrors = ErrorHandler.getCustomHttpErrors(error);
        const toastService = getToastService();

        const {
            isApiError,
            httpStatusCode,
            errorCode,
            //errorMessage,
            checkError,
        }: CustomHttpErrors = customHttpErrors;

        const dataError = {
            log_level: 'error',
            timestamp: new Date(),
            context: { userAgent: navigator.userAgent, location: window.location },
            error,
        };

        if (httpStatusCode === HttpStatusCode.Unauthorized) {
            //* soft logout user : remove authentication data
            //* then redirect to login
            this.LogError(
                '🔥🔥🔥🔥🔥 | DetamErrorHandler Unauthorized',
                customHttpErrors,
                dataError
            );
        }

        if (httpStatusCode === HttpStatusCode.BadRequest) {
            this.LogError('🔥🔥🔥🔥🔥 | DetamErrorHandler BadRequest', customHttpErrors, dataError);
        }

        if (httpStatusCode === HttpStatusCode.Forbidden) {
            this.LogError('🔥🔥🔥🔥🔥 | DetamErrorHandler Forbidden', customHttpErrors, dataError);

            await this.authService.value().logout('from Forbidden request');
            toastService.value().showError(this.i18nService.getString('Error_FORBIDDEN'));
            getNavigationService().value().goTo(AppRoutes.LOGIN, true);
            return Promise.resolve(this.ERROR_HANDLED);
        }

        if (httpStatusCode === HttpStatusCode.BadRequest) {
            this.LogError('🔥🔥🔥🔥🔥 | DetamErrorHandler BadRequest', customHttpErrors, dataError);
        }

        if (isApiError && checkError) {
            //* let message = 'An error occurred with the server';
            this.LogError('🔥🔥🔥🔥🔥 | DetamErrorHandler  Api', customHttpErrors, dataError);

            const message =
                this.i18nService.getString(`Error_${errorCode}`) ||
                errorCode ||
                //errorMessage || //* can be generic error so not usable
                this.i18nService.getString(`Error_GenericError`) ||
                //* default message in FR 🇫🇷
                "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.";

            toastService.value().showError(message);
        }

        return Promise.resolve(this.ERROR_HANDLED);
    };

    private LogError = (logMessage: string, customHttpErrors: CustomHttpErrors, dataError: any) => {
        Debugger.LogValue(`${logMessage}`, { ...customHttpErrors, dataError });
    };
}
