import { Ability } from '@casl/ability';
import { Theme } from '@material-ui/core/styles';
import React from 'react';

import { AbilityProvider } from '../../AbilityProvider/AbilityProvider';
import { CustomDialogContainer } from '../../CustomDialogContainer/CustomDialogContainer';
import { CustomToastContainer } from '../../CustomToastContainer/CustomToastContainer';
import { MuiApp } from '../../MuiApp/MuiApp';
import { ReduxApp } from '../../ReduxApp/ReduxApp';
import { IntlWrapper } from '../IntlWrapper/IntlWrapper';
import { LazyLoader } from '../LazyLoader/LazyLoader';
import { MountActionsGate } from '../MountActionsGate/MountActionsGate';

export interface AppWrapperProps {
    store: any;
    persistor: any;
    content?: React.ReactNode;
    children?: React.ReactNode;

    //* Array of Promises to execute on mount
    mountActions: Array<Promise<boolean>>;
    //* Automatically setup react-intl & intl
    //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
    setupIntl: boolean;
    //* If should trigger 'BaseActions.triggerEssentialReload'
    withEssentialReload: boolean;
    dialogContainerId: string;

    //* Mui Theme
    getMuiTheme: () => Theme;

    //* Permissions from store like Me Permissions / Ability
    getPermissions?: () => Ability;
}

const AppWrapper = ({
    store,
    persistor,
    content,
    children,
    mountActions = [],
    setupIntl = false,
    withEssentialReload = false,
    dialogContainerId,
    getMuiTheme,
    getPermissions,
}: AppWrapperProps) => (
    <MuiApp getMuiTheme={getMuiTheme}>
        <ReduxApp store={store} persistor={persistor}>
            <AbilityProvider getPermissions={getPermissions}>
                <MountActionsGate
                    actions={mountActions}
                    setupIntl={setupIntl}
                    withEssentialReload={withEssentialReload}
                >
                    <IntlWrapper>
                        <LazyLoader>
                            <div style={{ height: '100%' }}>
                                {children ? children : content}
                                <CustomToastContainer />
                                <CustomDialogContainer containerId={dialogContainerId} />
                            </div>
                        </LazyLoader>
                    </IntlWrapper>
                </MountActionsGate>
            </AbilityProvider>
        </ReduxApp>
    </MuiApp>
);

export { AppWrapper };
