import orange from '@material-ui/core/colors/deepOrange';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Theme } from '@material-ui/core/styles';

export class ThemeMuiProvider {

    public static provideTheme = (): Theme => {
        return createMuiTheme({
            palette: {
                primary: { main: '#58d681' },
                secondary: orange,
                type: 'light',
            },
            typography: {
                button: { textTransform: 'none' }
            }
        });
    }
}