import { ApiDataResponse } from '../ApiData';
import { MaterielWithAttributions } from '../Materiel/Materiel';
import { UserWithAttributions } from '../Users/User';

export interface AttributionItem {
    id: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    item: UserWithAttributions | MaterielWithAttributions;
}

export interface AttributionProductionProperties {
    phase_id: string;
}

//#region COMPAGNON

export enum CompagnonAttributionType {
    Production = 'Production',
    Conges = 'Conges',
    Formation = 'Formation',
}

export interface CompagnonAttribution {
    id: string;
    start_day_date: string;
    end_day_date: string;
    compagnon_id: string;
}
export interface CompagnonAttributionDetailsDto {
    start_day_date: string;
    end_day_date: string;
    type: CompagnonAttributionType;
    formation_properties?: AttributionFormationProperties;
    conges_properties?: AttributionCongesProperties;
}

export interface AddCompagnonAttributionRequest {
    start_day_date: string;
    end_day_date: string;
    type: CompagnonAttributionType;
    production_properties?: AttributionProductionProperties;
    conges_properties?: AttributionCongesProperties;
    formation_properties?: AttributionFormationProperties;
}

export interface UpdateCompagnonAttributionRequest {
    start_day_date: string;
    end_day_date: string;
    type: CompagnonAttributionType;
    conges_properties?: AttributionCongesProperties;
    formation_properties?: AttributionFormationProperties;
}

export interface AttributionFormationProperties {
    comment: string;
}

export interface AttributionCongesProperties {
    comment: string;
}

//#endregion

//#region MATERIEL

export enum MaterielAttributionType {
    Production = 'Production',
    Maintenance = 'Maintenance',
}

export interface MaterielAttribution {
    id: string;
    start_day_date: string;
    end_day_date: string;
    materiel_id: string;
}

export interface AttributionMaintenanceProperties {
    comment: string;
}

export interface AddAttributionMateriel {
    start_day_date: string;
    end_day_date: string;
    type: MaterielAttributionType;
    production_properties?: AttributionProductionProperties;
    maintenance_properties?: AttributionMaintenanceProperties;
}
export interface MaterielAttributionDetailDto {
    id: string;
    start_day_date: string;
    end_day_date: string;
    type: MaterielAttributionType;
    maintenance_properties?: AttributionMaintenanceProperties;
}

//#endregion

export type CompagnonAttributionResponse = ApiDataResponse<CompagnonAttributionDetailsDto>;

export type MaterielAttributionResponse = ApiDataResponse<MaterielAttributionDetailDto>;
