import { IoC } from '../ServicesContainer';

export interface INavigationService {
    getHomeLink: string;

    goTo: (route: string, replace?: boolean) => void;
    goBack: () => void;

    goToHome: () => void;
    goToLogin: () => void;

    //* SITES
    getSiteDetails: (id: string) => string;
    goToSiteDetails: (id: string) => void;
    goToSiteDetailsPlanning: (id: string) => void;
    getSiteList: () => string;
    goToSiteList: () => void;
    goToSiteCreate: () => void;

    //* USERS
    getUserDetails: (id: string) => string;
    goToUserCreate: () => void;
    goToUserList: () => void;

    //* MATERIELS
    goToEquipmentDetails: (id: string) => void;
    goToEquipmentCreate: () => void;
    goToEquipmentList: () => void;
}

const NavigationServiceName = 'NavigationService';
const getNavigationService = () => IoC.getLazy<INavigationService>(NavigationServiceName);

export { NavigationServiceName, getNavigationService };
