import { AppRoutes } from '../../../Core/App/AppRoutes';
import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { SiteListPageLoading } from '../../Pages/Sites/SiteListPage/SiteListPageLoading';
import { SiteCreatePageLoading } from '../../Pages/Sites/SiteCreatePage/SiteCreatePageLoading';
import { SiteDetailsPageLoading } from '../../Pages/Sites/SiteDetailsPage/SiteDetailsPageLoading';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';

export class SiteRoutes {
    private static getSiteListRoute = (props: object): AppRouteType => ({
        id: AppRoutes.SITE_LIST,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.SITE_LIST,
            component: SiteListPageLoading,
            ...props,
        },
    });

    private static getSiteCreateRoute = (props: object): AppRouteType => ({
        id: AppRoutes.SITE_CREATE,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.SITE_CREATE,
            component: SiteCreatePageLoading,
            ...props,
        },
    });

    private static getSiteDetailsRoute = (props: object): AppRouteType => ({
        id: AppRoutes.SITE_DETAILS,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.SITE_DETAILS,
            component: SiteDetailsPageLoading,
            ...props,
        },
    });

    public static getSiteRoutes = (args: object) => [
        SiteRoutes.getSiteListRoute(args),
        SiteRoutes.getSiteCreateRoute(args),
        SiteRoutes.getSiteDetailsRoute(args),
    ];
}
