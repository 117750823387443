import { IoC } from '../ServicesContainer';

export type DialogAction = () => void | Promise<void>;

export enum DialogStyle {
    Default,
    Danger
}

export interface IDialogService {
    showDialog(
        title: string,
        content: string,
        leftActionTitle: string,
        rightActionTitle: string,
        leftAction: DialogAction,
        rightAction: DialogAction,
        onClosed: DialogAction,
        style?: DialogStyle
    ): void;
}

const DialogServiceName = 'DialogService';
const getDialogService = () => IoC.getLazy<IDialogService>(DialogServiceName);

export { DialogServiceName, getDialogService };
