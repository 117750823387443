import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { AuthRoutes } from './Routes/AuthRoutes';
import { CompagonRoutes } from './Routes/CompagonRoutes';
import { PlanningRoutes } from './Routes/PlanningRoutes';
import { UserRoutes } from './Routes/UserRoutes';
import { EquipmentRoutes } from './Routes/EquipmentRoutes';
import { SiteRoutes } from './Routes/SiteRoutes';

const DEFAULT_ROUTE = AppRoutes.PLANNING;

const getAppRoutes = (): AppRouteType[] => [
    ...AuthRoutes.getAuthRoutes({}),
    ...PlanningRoutes.getPlanningRoutes({}),
    ...SiteRoutes.getSiteRoutes({}),
    ...CompagonRoutes.getCompagonRoutes({}),
    ...EquipmentRoutes.getEquipmentRoutes({}),
    ...UserRoutes.getUserRoutes({}),
];

export { getAppRoutes, DEFAULT_ROUTE };
