import { persistor, store } from './Configs/store/store';
import { CoreBootstrapper } from './Core/CoreBootstrapper';
import { Debugger } from './Core/Errors/Debugger';
import { DialogServiceName, IDialogService } from './Core/Services/Interfaces/IDialogService';
import { INavigationService, NavigationServiceName } from './Core/Services/Interfaces/INavigationService';
import { IToastService, ToastServiceName } from './Core/Services/Interfaces/IToastService';
import { IoC } from './Core/Services/ServicesContainer';
import { AppRouteType } from './Web/Components/Templates/AppRouter/AppRouteType';
import { DEFAULT_ROUTE, getAppRoutes } from './Web/Routers/AppRoutes';
import { DialogContainerId, DialogService } from './Web/Services/DialogService';
import { NavigationService } from './Web/Services/NavigationService';
import { ToastService } from './Web/Services/ToastService';

export interface BootstrapperInitialized {
    store: any;
    persistor: any;
    mountActions?: Array<Promise<boolean>>; //* actions to execute before page is mounted
    defaultRoote: string;
    routes: Array<AppRouteType>;
    dialogContainerId: string;
}

export class AppBootstrapper extends CoreBootstrapper {
    public initialize = (): BootstrapperInitialized => {
        Debugger.Log('🚀🚀🚀🚀🚀 | AppBootstrapper start initialize');
        //* Initialize Services
        Debugger.Log('🚀🚀🚀🚀🚀 | AppBootstrapper Initialize Services');
        this.initializeWebServices();
        Debugger.Log('🚀🚀🚀🚀🚀 | initializeWebServices done');
        this.initializeCoreServices();
        Debugger.Log('🚀🚀🚀🚀🚀 | initializeCoreServices done');

        //* Initialize store and api client
        this.initializeCore();

        Debugger.Log('🚀🚀🚀🚀🚀 | Initialize api client done');
        const routes: AppRouteType[] = getAppRoutes();
        Debugger.Log('🚀🚀🚀🚀🚀 | Initialize app routes done');

        Debugger.Log('🚀🚀🚀🚀🚀 | initialize done');

        return {
            store,
            routes,
            persistor,
            defaultRoote: DEFAULT_ROUTE,
            dialogContainerId: DialogContainerId,
        };
    };

    private initializeWebServices = (): void => {
        IoC.bind<IToastService>(ToastServiceName, new ToastService());
        IoC.bind<IDialogService>(DialogServiceName, new DialogService());
        IoC.bind<INavigationService>(NavigationServiceName, new NavigationService());
    };
}
