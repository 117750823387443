import * as internalLogger from 'loglevel';
import { IoC } from './ServicesContainer';
import { Constants } from '../Constants';

export interface ILogService {
    log: (data: any) => void;
    debugLog: (...msg: any[]) => void;
    error: (message: string, data: any) => void;
}

export class PPLusLogService implements ILogService {
    private readonly logEnabled: boolean;

    constructor() {
        this.logEnabled = Constants.isDev;
        internalLogger.setDefaultLevel(internalLogger.levels.SILENT);
        if (process.env.NODE_ENV === 'development') {
            internalLogger.setLevel(internalLogger.levels.DEBUG);
            //window.appLog = log;
        }
    }

    public log = (data: any): void => {
        if (this.logEnabled) {
            internalLogger.log(data);
        }
    };
    public debugLog(message: string, value: any) {
        if (this.logEnabled) {
            internalLogger.log(message, value);
        }
    }

    public error = (message: string, data: any) => {
        if (this.logEnabled) {
            internalLogger.error(message, data);
        }
    };
}

const LogServiceName = 'LogService';
const getLogService = () => IoC.getLazy<ILogService>(LogServiceName);
export { LogServiceName, getLogService };
