import { ToastId } from 'react-toastify';

import { IoC } from '../ServicesContainer';

export interface ToastActionOptions {
    actionTitle: string;
    action?: () => void;
    hideToastActionTrigger?: () => void;
}

export interface DownloadOptions {
    fileName: string;
    content: string;
    success?: boolean;
    failed?: boolean;
}

export interface IToastService {
    showSuccess(content: string): ToastId;

    showError(content: string, actionOptions?: ToastActionOptions): ToastId;

    showInfo(content: string, actionOptions?: ToastActionOptions): ToastId;

    showSuccessUpdate(): ToastId;

    //Download
    showDownloadInfo(downloadOptions: DownloadOptions): ToastId;
    updateDownloadInfo(toastId: ToastId, downloadOptions: DownloadOptions): ToastId;

    notImplemented(): ToastId;

    dismiss(toastId: ToastId): void;
}

const ToastServiceName = 'ToastService';
const getToastService = () => IoC.getLazy<IToastService>(ToastServiceName);

export { ToastServiceName, getToastService };
