import Loadable from 'react-loadable';

import { AsyncPageLoading } from './AsyncPageLoading';

const DELAY_MS = 200;

//* loader(): Promise<React.ComponentType<Props> | { default: React.ComponentType<Props> }>;
export const withAsyncLoading = (asyncPageImport: () => Promise<React.ComponentType>) => {
    return Loadable({
        loader: asyncPageImport,
        loading: AsyncPageLoading,
        delay: DELAY_MS,
    });
};

export const withAsyncPageLoading = <TProps extends {}>(
    asyncPageImport: () => Promise<React.ComponentType<TProps>>
) => {
    return Loadable({
        loader: asyncPageImport,
        loading: AsyncPageLoading,
        delay: DELAY_MS,
    });
};
