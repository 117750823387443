import './BaseDialog.scss';

import { DialogProps, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import React from 'react';

export interface BaseDialogProps extends DialogProps {
    title?: string;
    content?: React.ReactNode;
    onClose?(): void;
    hideCloseButton?: boolean;
    renderTitle?: () => React.ReactNode;
    children: React.ReactNode;
    withRadius?: boolean;
    baseDialogClasses?: {
        header?: string;
        body?: string;
    };
}

const BaseDialog = ({
    title,
    content,
    onClose,
    children,
    hideCloseButton = false,
    renderTitle,
    withRadius = true,
    baseDialogClasses,
    ...rest
}: BaseDialogProps) => {
    const dialogContainerClasses = classNames({
        'base-dialog-container': true,
        withRadius,
    });

    const useOverrideHeaderClass = baseDialogClasses?.header;
    const dialogHeaderClasses = classNames({
        'base-dialog-header': !useOverrideHeaderClass,
        [baseDialogClasses?.header ?? '']: useOverrideHeaderClass,
    });

    const useOverrideBodyClass = baseDialogClasses?.body;
    const dialogBodyClasses = classNames({
        'base-dialog-body': !useOverrideBodyClass,
        [baseDialogClasses?.body ?? '']: useOverrideBodyClass,
    });

    const RenderDialogTitle = (): React.ReactNode => (
        <React.Fragment>
            {title && <DialogTitle className="base-dialog-header-title">{title}</DialogTitle>}
        </React.Fragment>
    );

    return (
        <Dialog onClose={onClose} {...rest}>
            <div className={dialogContainerClasses}>
                <div className={dialogHeaderClasses}>
                    {renderTitle ? renderTitle() : RenderDialogTitle()}
                </div>
                <div className={dialogBodyClasses}>{content || children}</div>
            </div>
        </Dialog>
    );
};

export { BaseDialog };
