import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { Constants } from '../Constants';
import { BaseApiDataResponse } from '../Models/ApiData';
import {
    AuthenticationResponse,
    GrantType,
    LoginRequest,
    RefreshRequest,
    ResetPassword,
    ResetPasswordAuthenticate,
} from '../Models/Authentication/AuthResponse';
import { BaseDataService } from './Base/BaseDataService';

export const END_POINTS = {
    LOGIN: '/api/v1/back/oauth2/authenticate',
    LOGOUT: '/api/v1/back/oauth2/logout',
    REFRESH: '/api/v1/back/oauth2/refresh',
    RESET_PASSWORD: '/api/v1/back/passwords/reset',
    RESET_PASSWORD_AUTHENTICATE: (token: string) => `/api/v1/back/passwords/reset/${token}`,
};

export interface IAuthDataService {
    login: (
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ) => Promise<AuthenticationResponse>;
    logout: () => Promise<BaseApiDataResponse>;
    refresh: (refreshToken: string, cts?: XHRRequestCanceler) => Promise<AuthenticationResponse>;
    resetPassword: (email: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
    resetPasswordAndAuthenticate: (
        token: string,
        email: string,
        newPassword: string,
        cts: XHRRequestCanceler
    ) => Promise<AuthenticationResponse>;
}

class AuthDataService extends BaseDataService implements IAuthDataService {
    async login(
        email: string,
        password: string,
        cts: XHRRequestCanceler
    ): Promise<AuthenticationResponse> {
        const body: LoginRequest = {
            email: email,
            password: password,
            grant_type: GrantType.Password,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return this.post<AuthenticationResponse>(END_POINTS.LOGIN, body, cts, {
            checkError: false,
            noAuth: true,
        });
    }
    async logout(): Promise<BaseApiDataResponse> {
        return this.post<BaseApiDataResponse>(END_POINTS.LOGOUT, {});
    }
    async refresh(refreshToken: string, cts?: XHRRequestCanceler): Promise<AuthenticationResponse> {
        const body: RefreshRequest = {
            refresh_token: refreshToken,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return this.post<AuthenticationResponse>(END_POINTS.REFRESH, body, cts, {
            checkError: false,
            noAuth: true,
        });
    }
    async resetPassword(email: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
        const body: ResetPassword = {
            email: email,
        };
        return this.post<BaseApiDataResponse>(END_POINTS.RESET_PASSWORD, body, cts, {
            checkError: false,
            noAuth: true,
        });
    }
    async resetPasswordAndAuthenticate(
        token: string,
        email: string,
        newPassword: string,
        cts: XHRRequestCanceler): Promise<AuthenticationResponse> {
        const body: ResetPasswordAuthenticate = {
            email: email,
            new_password: newPassword,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return this.post<AuthenticationResponse>(END_POINTS.RESET_PASSWORD_AUTHENTICATE(token), body, cts, {
            checkError: false,
            noAuth: true,
        });
    }
}

const AuthDataServiceName = 'AuthDataService';
export { AuthDataService, AuthDataServiceName };
