import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AuthenticationReducer } from '../../Core/Services/Data/Authentication/AuthenticationReducer';
import { I18nReducer } from '../../Core/Services/Data/I18n/i18nReducer';
import { MeReducer } from '../../Core/Services/Data/Me/MeReducer';
import { RouterReducer } from '../history/history';

export const rawAppReducer = combineReducers({
    router: RouterReducer,
    I18n: I18nReducer,
    Authentication: AuthenticationReducer,
    Me: MeReducer,
});

export const ApplicationReducer = persistReducer(
    {
        key: 'detam-back-office-root',
        whitelist: ['Authentication'],
        storage,
    },
    rawAppReducer
);

export type AppState = ReturnType<typeof rawAppReducer>;
