import { Ability, defineAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import React from 'react';

//* A FUNC which return a Object wich extends Ability
export type BoundAbilityCreator<BoundAbilityType extends Ability> = (
    ability: Ability
) => BoundAbilityType;

export interface AbilityProviderProps {
    children?: React.ReactNode;
    getPermissions?: () => Ability;
}

const DefaultPermssions: Ability = defineAbility((): void => {});

export const AbilityContext = React.createContext<Ability>(DefaultPermssions);

export const AbilityProvider = ({ getPermissions, children }: AbilityProviderProps) => {
    const ability = getPermissions ? getPermissions() : DefaultPermssions;
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useAbilityContext = (): Ability => React.useContext(AbilityContext);
export const Can = createContextualCan(AbilityContext.Consumer);

//* NOT USED ANY WHERE
export const useBoundAbilityFromContext = <BoundAbilityType extends Ability>(
    creator: BoundAbilityCreator<BoundAbilityType>
): BoundAbilityType => {
    const ability = useAbilityContext();
    return creator(ability);
};
