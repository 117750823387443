import { XHRRequestConfig } from '../../Libs/xhr/XHRRequest';
import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { DateHelpers } from '../Helpers/DateHelpers';
import { ApiUrls } from '../Models/ApiUrls';
import { PlanningChantiersResponse } from '../Models/Planning/PlanningChantiersModels';
import { PlanningCompagnonsResponse } from '../Models/Planning/PlanningCompagnonsModels';
import { PlanningMaterielsResponse } from '../Models/Planning/PlanningMaterielsModels';
import { PlanningRequestParams } from '../Models/Planning/PlanningRequestParams';
import { BaseDataService } from './Base/BaseDataService';

export interface IPlanningDataService {
    getPlanningChantiers: (params: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningChantiersResponse>;

    getPlanningCompagnons: (params: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningCompagnonsResponse>;

    getPlanningEquipments: (params: PlanningRequestParams, cts?: XHRRequestCanceler
    ) => Promise<PlanningMaterielsResponse>;
}
export class PlanningDataService extends BaseDataService implements IPlanningDataService {

    public static NAME = 'PlanningDataService';

    public getPlanningChantiers(
        { startDayDate, endDayDate, natureTravaux }: PlanningRequestParams,
        cts?: XHRRequestCanceler
    ): Promise<PlanningChantiersResponse> {
        const params = { nature_travaux: natureTravaux };
        const config: XHRRequestConfig = { params };

        const startDay = DateHelpers.toDayDate(startDayDate);
        const endDay = DateHelpers.toDayDate(endDayDate);
        return this.get<PlanningChantiersResponse>(ApiUrls.PLANNING_CHANTIERS(startDay, endDay), cts, config);
    }

    public getPlanningCompagnons(
        { startDayDate, endDayDate }: PlanningRequestParams,
        cts?: XHRRequestCanceler
    ): Promise<PlanningCompagnonsResponse> {
        const startDay = DateHelpers.toDayDate(startDayDate);
        const endDay = DateHelpers.toDayDate(endDayDate);
        return this.get<PlanningCompagnonsResponse>(ApiUrls.PLANNING_COMPAGNONS(startDay, endDay), cts);
    }

    public getPlanningEquipments(
        { startDayDate, endDayDate }: PlanningRequestParams,
        cts?: XHRRequestCanceler
    ): Promise<PlanningMaterielsResponse> {
        const startDay = DateHelpers.toDayDate(startDayDate);
        const endDay = DateHelpers.toDayDate(endDayDate);
        return this.get<PlanningMaterielsResponse>(ApiUrls.PLANNING_MATERIELS(startDay, endDay), cts);
    }
}
