import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiIdDataResponse, BaseApiDataResponse } from '../Models/ApiData';
import { ApiUrls } from '../Models/ApiUrls';
import { AddAttributionMateriel, MaterielAttributionResponse } from '../Models/Attribution/Attribution';
import { MaterielDetailsResponse, MaterielListWithAttributions, SetMaterielRequest } from '../Models/Materiel/Materiel';
import { BaseDataService } from './Base/BaseDataService';

export interface IMaterielDataService {
    getMaterielListWithAttribution: (
        params: { wished_start_day_date: string },
        cts: XHRRequestCanceler
    ) => Promise<MaterielListWithAttributions>;

    getMateriel: (materielId: string, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    createMateriel: (body: SetMaterielRequest, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    updateMateriel: (materielId: string, body: SetMaterielRequest, cts: XHRRequestCanceler)
        => Promise<MaterielDetailsResponse>;

    createMaterielAttribution: (
        materielId: string,
        requestBody: AddAttributionMateriel,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getMaterielAttribution: (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ) => Promise<MaterielAttributionResponse>;

    deleteMaterielAttribution: (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;
}

class MaterielDataService extends BaseDataService implements IMaterielDataService {
    public getMaterielListWithAttribution = (
        params: { wished_start_day_date: string },
        cts: XHRRequestCanceler
    ): Promise<MaterielListWithAttributions> => {
        return this.get<MaterielListWithAttributions>(ApiUrls.MATERIEL_WITH_ATTRIBUTION, cts, {
            params,
        });
    };

    public getMateriel = (
        materielId: string,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        return this.get<MaterielDetailsResponse>(ApiUrls.MATERIEL_DETAILS(materielId), cts,);
    };

    public createMateriel = (
        body: SetMaterielRequest,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        return this.post<MaterielDetailsResponse>(ApiUrls.MATERIEL_CREATE, body, cts,);
    };

    public updateMateriel = (
        materielId: string,
        body: SetMaterielRequest,
        cts: XHRRequestCanceler
    ): Promise<MaterielDetailsResponse> => {
        return this.patch<MaterielDetailsResponse>(ApiUrls.MATERIEL_UPDATE(materielId), body, cts,);
    };

    public createMaterielAttribution = (
        materielId: string,
        body: AddAttributionMateriel,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        return this.post<MaterielDetailsResponse>(ApiUrls.MATERIEL_CREATE_ATTRIBUTION(materielId), body, cts,);
    };

    public getMaterielAttribution = (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ): Promise<MaterielAttributionResponse> => {
        return this.get<MaterielAttributionResponse>(ApiUrls.MATERIEL_GET_ATTRIBUTION(materielId, attributionMaterielId), cts,);
    };

    public deleteMaterielAttribution = (
        materielId: string,
        attributionMaterielId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        return this.delete<BaseApiDataResponse>(ApiUrls.MATERIEL_DELETE_ATTRIBUTION(materielId, attributionMaterielId), cts,);
    };
}

const MaterielDataServiceName = 'MaterielDataService';
export { MaterielDataService, MaterielDataServiceName };
