import React from 'react';

import { AppBootstrapper, BootstrapperInitialized } from './AppBootstrapper';
import { AppRouter } from './Web/Components/Templates/AppRouter/AppRouter';
import { AppWrapper } from './Web/Components/Templates/Wrappers/AppWrapper/AppWrapper';
import { StateLoadingGate } from './Web/Components/Templates/Wrappers/StateLoadingGate/StateLoadingGate';
import { ThemeMuiProvider } from './Web/Themes/ThemeMuiProvider';

const boot: AppBootstrapper = new AppBootstrapper();
const {
    store,
    routes,
    persistor,
    defaultRoote,
    mountActions = [],
    dialogContainerId,
}: BootstrapperInitialized = boot.initialize();

const Application: React.FunctionComponent = () => (
    <AppWrapper
        setupIntl
        store={store}
        persistor={persistor}
        withEssentialReload
        mountActions={mountActions}
        dialogContainerId={dialogContainerId}
        getMuiTheme={() => ThemeMuiProvider.provideTheme()}
    >
        <StateLoadingGate loadableStateNames={['Authentication']}>
            <AppRouter routes={routes} defaultRoote={defaultRoote} />
        </StateLoadingGate>
    </AppWrapper>
);

export { Application };
