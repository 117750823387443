import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IUsersDataService, UsersDataServiceName } from '../../../DataServices/UsersDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { ApiIdDataResponse, BaseApiDataResponse } from '../../../Models/ApiData';
import { CompagnonAttributionResponse, CompagnonAttributionType } from '../../../Models/Attribution/Attribution';
import {
    UserDetails,
    UserDetailsApiResponse,
    UserListResponse,
    UserListWithAttributions,
    UserType,
} from '../../../Models/Users/User';
import { DateUtils } from '../../../Utils/DateUtils';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { I18nServiceName, II18nService } from '../I18n/I18nService';

export interface IUsersService {
    getUsersList: (
        params: {
            type?: UserType;
            is_active?: boolean;
            Page?: number;
            Count?: number;
        },
        cts: XHRRequestCanceler
    ) => Promise<UserListResponse>;

    getUserDetails: (userId: string, cts: XHRRequestCanceler) => Promise<UserDetailsApiResponse>;

    createUser: (cts: XHRRequestCanceler, user: UserDetails) => Promise<ApiIdDataResponse>;

    modifyUser: (
        user: UserDetails,
        id: string,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getUserListWithAttribution: (
        params: { startDate: Date },
        cts: XHRRequestCanceler
    ) => Promise<UserListWithAttributions>;

    createUserAttribution: (
        compagnonId: string,
        body: {
            startDate: Date | undefined;
            endDate: Date | undefined;
            compagnon: UserDetails | undefined;
            phaseId: string | undefined;
            congesComment: string | undefined;
            formationComment: string | undefined;
            type: CompagnonAttributionType;
        },
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getUserAttribution: (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ) => Promise<CompagnonAttributionResponse>;

    updateUserAttribution: (
        compagnonId: string,
        attributionId: string,
        body: {
            startDate: Date | undefined;
            endDate: Date | undefined;
            compagnon: UserDetails | undefined;
            phaseId: string | undefined;
            congesComment: string | undefined;
            formationComment: string | undefined;
            type: CompagnonAttributionType;
        },
        cts: XHRRequestCanceler
    ) => Promise<CompagnonAttributionResponse>;

    deleteUserAttribution: (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    sendInvitation: (userId: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

class UsersService extends BaseReduxService implements IUsersService {
    private readonly usersDataService: Lazy<IUsersDataService>;
    private readonly i18nService: Lazy<II18nService>;

    constructor() {
        super();
        this.usersDataService = IoC.getLazy<IUsersDataService>(UsersDataServiceName);
        this.i18nService = IoC.getLazy<II18nService>(I18nServiceName);
    }

    public getUsersList = async (
        params: { type?: UserType; is_active?: boolean; Page?: number; Count?: number },
        cts: XHRRequestCanceler
    ): Promise<UserListResponse> => {
        try {
            const response = await this.usersDataService.value().getUsersList(params, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getUserDetails = async (
        userId: string,
        cts: XHRRequestCanceler
    ): Promise<UserDetailsApiResponse> => {
        try {
            const response = await this.usersDataService.value().getUserDetails(userId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public createUser = async (
        cts: XHRRequestCanceler,
        user: UserDetails
    ): Promise<ApiIdDataResponse> => {
        try {
            const response = await this.usersDataService.value().createUser(user, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public modifyUser = async (
        user: UserDetails,
        id: string,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const response = await this.usersDataService.value().modifyUser(user, id, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getUserListWithAttribution = async (
        params: { startDate: Date },
        cts: XHRRequestCanceler
    ): Promise<UserListWithAttributions> => {
        try {
            const response = await this.usersDataService.value().getUserListWithAttribution(
                {
                    wished_start_day_date: params.startDate
                        ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                            day: DateUtils.formatTwoDigitsNumber(params.startDate?.getDate()),
                            month: DateUtils.formatTwoDigitsNumber(
                                params.startDate?.getMonth() + 1
                            ),
                            year: params.startDate?.getFullYear(),
                        })
                        : '',
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public createUserAttribution = async (
        compagnonId: string,
        body: {
            startDate: Date | undefined;
            endDate: Date | undefined;
            compagnon: UserDetails | undefined;
            phaseId: string | undefined;
            congesComment: string | undefined;
            formationComment: string | undefined;
            type: CompagnonAttributionType;
        },
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const response: ApiIdDataResponse = await this.usersDataService
                .value()
                .createUserAttribution(
                    compagnonId,
                    {
                        start_day_date: DateUtils.formatDateAsDayDateString(
                            body.startDate || new Date()
                        ),
                        end_day_date: DateUtils.formatDateAsDayDateString(
                            body.endDate || new Date()
                        ),
                        conges_properties:
                            body.type === CompagnonAttributionType.Conges
                                ? {
                                    comment: body.congesComment || '',
                                }
                                : undefined,
                        formation_properties:
                            body.type === CompagnonAttributionType.Formation
                                ? {
                                    comment: body.formationComment || '',
                                }
                                : undefined,
                        production_properties:
                            body.type === CompagnonAttributionType.Production
                                ? {
                                    phase_id: body.phaseId || '',
                                }
                                : undefined,
                        type: body.type,
                    },
                    cts
                );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getUserAttribution = async (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ): Promise<CompagnonAttributionResponse> => {
        try {
            const response: CompagnonAttributionResponse = await this.usersDataService
                .value()
                .getUserAttribution(compagnonId, attributionId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public updateUserAttribution = async (
        compagnonId: string,
        attributionId: string,
        body: {
            startDate: Date | undefined;
            endDate: Date | undefined;
            compagnon: UserDetails | undefined;
            phaseId: string | undefined;
            congesComment: string | undefined;
            formationComment: string | undefined;
            type: CompagnonAttributionType;
        },
        cts: XHRRequestCanceler
    ): Promise<CompagnonAttributionResponse> => {
        try {
            const response: CompagnonAttributionResponse = await this.usersDataService
                .value()
                .updateUserAttribution(
                    compagnonId,
                    attributionId,
                    {
                        start_day_date: DateUtils.formatDateAsDayDateString(
                            body.startDate || new Date()
                        ),
                        end_day_date: DateUtils.formatDateAsDayDateString(
                            body.endDate || new Date()
                        ),
                        conges_properties:
                            body.type === CompagnonAttributionType.Conges
                                ? {
                                    comment: body.congesComment || '',
                                }
                                : undefined,
                        formation_properties:
                            body.type === CompagnonAttributionType.Formation
                                ? {
                                    comment: body.formationComment || '',
                                }
                                : undefined,
                        type: body.type,
                    },
                    cts
                );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public deleteUserAttribution = async (
        compagnonId: string,
        attributionId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.usersDataService
                .value()
                .deleteUserAttribution(compagnonId, attributionId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public sendInvitation = async (
        userId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.usersDataService
                .value()
                .sendInvitation(userId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

const UsersServiceName = 'UsersService';
export { UsersService, UsersServiceName };
