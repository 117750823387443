import { AppRoutes } from '../../../Core/App/AppRoutes';
import { AppRouteType } from '../../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../../Components/Templates/AppRouter/PrivateRoute';
import { EquipmentListPageLoading } from '../../Pages/Equipments/EquipmentListPage/EquipmentListPageLoading';

export class EquipmentRoutes {
    private static getEquipmentListRoute = (props: object): AppRouteType => ({
        id: AppRoutes.EQUIPMENT_LIST,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.EQUIPMENT_LIST,
            component: EquipmentListPageLoading,
            ...props,
        },
    });

    private static getEquipmentCreateRoute = (props: object): AppRouteType => ({
        id: AppRoutes.EQUIPMENT_CREATE,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.EQUIPMENT_CREATE,
            component: EquipmentListPageLoading,
            ...props,
        },
    });

    private static getEquipmentDetailsRoute = (props: object): AppRouteType => ({
        id: AppRoutes.EQUIPMENT_DETAILS,
        render: PrivateRoute,
        props: {
            exact: true,
            path: AppRoutes.EQUIPMENT_DETAILS,
            component: EquipmentListPageLoading,
            ...props,
        },
    });

    public static getEquipmentRoutes = (args: object) => [
        EquipmentRoutes.getEquipmentListRoute(args),
        EquipmentRoutes.getEquipmentCreateRoute(args),
        EquipmentRoutes.getEquipmentDetailsRoute(args),
    ];
}
