import { ErrorHandlerName, IErrorHandler } from '../Libs/xhr/ErrorHandler';
import { Constants } from './Constants';
import {
    IPlanningDataService,
    PlanningDataService,
} from './DataServices/PlanningDataService';
import { PPLusErrorHandler } from './Errors/PPLusErrorHandler';
import { initializeApiClient } from './Services/Data/Api/ApiService';
import {
    AuthService,
    AuthServiceName,
    IAuthService,
} from './Services/Data/Authentication/AuthenticationService';
import { I18nService, I18nServiceName, II18nService } from './Services/Data/I18n/I18nService';
import { IMeService, MeService, MeServiceName } from './Services/Data/Me/MeService';
import {
    IPlanningService, PlanningService,
} from './Services/Data/Planning/PlanningService';
import { PPLusLogService, ILogService, LogServiceName } from './Services/PPLusLogService';
import { IoC } from './Services/ServicesContainer';
import {
    AuthDataServiceName,
    AuthDataService,
    IAuthDataService,
} from './DataServices/AuthenticationDataService';
import { ISitesService, SitesService, SitesServiceName } from './Services/Data/Sites/SitesService';
import {
    ISitesDataService,
    SitesDataService,
    SitesDataServiceName,
} from './DataServices/SitesDataService';
import { IUsersService, UsersServiceName, UsersService } from './Services/Data/Users/UsersService';
import {
    IUsersDataService,
    UsersDataServiceName,
    UsersDataService,
} from './DataServices/UsersDataService';
import {
    IMaterielService,
    MaterielServiceName,
    MaterielService,
} from './Services/Data/Materiel/MaterielService';
import {
    IMaterielDataService,
    MaterielDataServiceName,
    MaterielDataService,
} from './DataServices/MaterielDataService';
import {
    IGuidDataService,
    GuidDataServiceName,
    GuidDataService,
} from './DataServices/GuidDataService';
import {
    INotificationsService,
    NotificationsServiceName,
    NotificationsService,
} from './Services/Data/Notifications/NotificationsServices';
import {
    INotificationsDataService,
    NotificationsDataServiceName,
    NotificationsDataService
} from './DataServices/NotificationsDataService';
import {
    IAlertsService,
    AlertsServiceName,
    AlertsService
} from './Services/Data/Notifications/AlertsServices';
import {
    IAlertsDataService,
    AlertsDataServiceName,
    AlertsDataService,
} from './DataServices/AlertsDataService';

export class CoreBootstrapper {
    //* Initialize second/later (services)
    public initializeCore = (): void => {
        const appErrorHandler = IoC.get<IErrorHandler>(ErrorHandlerName);

        //* Setup Api client
        const timeout = 1000 * 60; //* 1 minute;
        initializeApiClient(Constants.getRoot(), appErrorHandler, timeout);
    };

    //* Initialize first (services)
    protected initializeCoreServices = (): void => {
        //* Create and bind LogService
        const logService: ILogService = new PPLusLogService();
        IoC.bind<ILogService>(LogServiceName, new PPLusLogService());

        //* Create and bind I18nService
        const i18nService: II18nService = new I18nService();
        IoC.bind<II18nService>(I18nServiceName, i18nService);

        //* Create and bind App ErrorHandler
        IoC.bind<IErrorHandler>(ErrorHandlerName, new PPLusErrorHandler(i18nService, logService));

        //* Register Redux services
        IoC.bind<IAuthService>(AuthServiceName, new AuthService());
        IoC.bind<IAuthDataService>(AuthDataServiceName, new AuthDataService());
        IoC.bind<IMeService>(MeServiceName, new MeService());

        IoC.bind<IPlanningDataService>(PlanningDataService.NAME, new PlanningDataService());
        IoC.bind<IPlanningService>(PlanningService.NAME, new PlanningService());

        //* SITES
        IoC.bind<ISitesService>(SitesServiceName, new SitesService());
        IoC.bind<ISitesDataService>(SitesDataServiceName, new SitesDataService());

        //* USERS
        IoC.bind<IUsersService>(UsersServiceName, new UsersService());
        IoC.bind<IUsersDataService>(UsersDataServiceName, new UsersDataService());

        //* MATERIEL
        IoC.bind<IMaterielService>(MaterielServiceName, new MaterielService());
        IoC.bind<IMaterielDataService>(MaterielDataServiceName, new MaterielDataService());

        //* GUIDS
        IoC.bind<IGuidDataService>(GuidDataServiceName, new GuidDataService());

        //* NOTIFICATIONS
        IoC.bind<INotificationsService>(NotificationsServiceName, new NotificationsService());
        IoC.bind<INotificationsDataService>(
            NotificationsDataServiceName,
            new NotificationsDataService()
        );

        IoC.bind<IAlertsService>(AlertsServiceName, new AlertsService());
        IoC.bind<IAlertsDataService>(
            AlertsDataServiceName,
            new AlertsDataService()
        );
    };
}
