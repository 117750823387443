import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { GuidDataServiceName, IGuidDataService } from '../../../DataServices/GuidDataService';
import { ISitesDataService, SitesDataServiceName } from '../../../DataServices/SitesDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { ApiIdDataResponse, BaseApiDataResponse } from '../../../Models/ApiData';
import { GetPaginatedQueryParameter } from '../../../Models/Commons/PaginatedQuery';
import {
    ChangeDocumentApiResponse,
    Document,
    DocumentListApiResponse,
    DocumentStatus,
} from '../../../Models/Sites/Documents';
import {
    CreateOrUpdateRDVServiceData,
    EventListApiResponse,
    PhaseApiDataResponse,
    PhaseListApiDataResponse,
    PlanificationRdvApiResponse,
} from '../../../Models/Sites/Planification';
import { SiteDetails, SiteDetailsResponse, SiteListResponse } from '../../../Models/Sites/Sites';
import { DateUtils } from '../../../Utils/DateUtils';
import {
    EventCreatePhaseState,
} from '../../../ViewModels/Sites/SiteDetails/SiteDetailsPlanification/SiteDetailsPlanificationEventCreate/SiteDetailsPlanificationEventCreateViewState';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { I18nServiceName, II18nService } from '../I18n/I18nService';

export interface ISitesService {
    getSiteList: (
        cts: XHRRequestCanceler,
        params?: GetPaginatedQueryParameter
    ) => Promise<SiteListResponse>;

    createSite: (body: SiteDetails, cts: XHRRequestCanceler) => Promise<ApiIdDataResponse>;

    getSiteDetails: (siteId: string, cts: XHRRequestCanceler) => Promise<SiteDetailsResponse>;

    editSite: (
        siteId: string,
        body: SiteDetails,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    getDocuments: (siteId: string, cts: XHRRequestCanceler) => Promise<DocumentListApiResponse>;

    setDocuments: (
        documents: Array<Document>,
        siteId: string,
        cts: XHRRequestCanceler
    ) => Promise<ChangeDocumentApiResponse>;

    editDocument: (
        params: { chantierId: string; documentId: string },
        document: Document | undefined,
        cts: XHRRequestCanceler
    ) => Promise<ChangeDocumentApiResponse>;

    getPlannificationEventList: (
        chantierId: string,
        cts: XHRRequestCanceler
    ) => Promise<EventListApiResponse>;

    createRdv: (
        chantierId: string,
        body: CreateOrUpdateRDVServiceData,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getRdv: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<PlanificationRdvApiResponse>;

    editRdv: (
        chantierId: string,
        eventId: string,
        body: CreateOrUpdateRDVServiceData,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    deleteRdv: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    createPhase: (
        chantierId: string,
        body: EventCreatePhaseState,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    getPhase: (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ) => Promise<PhaseApiDataResponse>;

    updatePhase: (
        chantierId: string,
        phaseId: string,
        body: EventCreatePhaseState,
        cts: XHRRequestCanceler
    ) => Promise<ApiIdDataResponse>;

    deletePhase: (
        chantierId: string,
        phaseId: string,
        cts: XHRRequestCanceler
    ) => Promise<BaseApiDataResponse>;

    getPhaseList: (
        params: {
            startDate: Date;
            endDate: Date;
        },
        cts: XHRRequestCanceler
    ) => Promise<PhaseListApiDataResponse>;
}

class SitesService extends BaseReduxService implements ISitesService {
    private readonly sitesDataService: Lazy<ISitesDataService>;
    private readonly guidsDataService: Lazy<IGuidDataService>;
    private readonly i18nService: Lazy<II18nService>;

    constructor() {
        super();
        this.sitesDataService = IoC.getLazy<ISitesDataService>(SitesDataServiceName);
        this.guidsDataService = IoC.getLazy<IGuidDataService>(GuidDataServiceName);
        this.i18nService = IoC.getLazy<II18nService>(I18nServiceName);
    }

    public getSiteList = async (
        cts: XHRRequestCanceler,
        params?: GetPaginatedQueryParameter
    ): Promise<SiteListResponse> => {
        try {
            const response: SiteListResponse = await this.sitesDataService
                .value()
                .getSiteList(cts, params);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public createSite = async (
        body: SiteDetails,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const response: ApiIdDataResponse = await this.sitesDataService.value().createSite(
                {
                    ...body,
                    contact_interne_list: undefined,
                    start_day_date: body.start_day_date
                        ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                            day: DateUtils.formatTwoDigitsNumber(body.start_day_date?.getDate()),
                            month: DateUtils.formatTwoDigitsNumber(
                                body.start_day_date?.getMonth() + 1
                            ),
                            year: body.start_day_date?.getFullYear(),
                        })
                        : undefined,
                    end_day_date: body.end_day_date
                        ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                            day: DateUtils.formatTwoDigitsNumber(body.end_day_date?.getDate()),
                            month: DateUtils.formatTwoDigitsNumber(
                                body.end_day_date?.getMonth() + 1
                            ),
                            year: body.end_day_date?.getFullYear(),
                        })
                        : undefined,
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getSiteDetails = async (
        siteId: string,
        cts: XHRRequestCanceler
    ): Promise<SiteDetailsResponse> => {
        try {
            const response: SiteDetailsResponse = await this.sitesDataService
                .value()
                .getSiteDetails(siteId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public editSite = async (
        siteId: string,
        body: SiteDetails,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.sitesDataService.value().editSite(
                siteId,
                {
                    ...body,
                    contact_interne_list: undefined,
                    start_day_date: body.start_day_date
                        ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                            day: DateUtils.formatTwoDigitsNumber(body.start_day_date?.getDate()),
                            month: DateUtils.formatTwoDigitsNumber(
                                body.start_day_date?.getMonth() + 1
                            ),
                            year: body.start_day_date?.getFullYear(),
                        })
                        : undefined,
                    end_day_date: body.end_day_date
                        ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                            day: DateUtils.formatTwoDigitsNumber(body.end_day_date?.getDate()),
                            month: DateUtils.formatTwoDigitsNumber(
                                body.end_day_date?.getMonth() + 1
                            ),
                            year: body.end_day_date?.getFullYear(),
                        })
                        : undefined,
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getDocuments = async (
        siteId: string,
        cts: XHRRequestCanceler
    ): Promise<DocumentListApiResponse> => {
        try {
            const response: DocumentListApiResponse = await this.sitesDataService
                .value()
                .getDocuments(siteId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public setDocuments = async (
        documents: Array<Document>,
        siteId: string,
        cts: XHRRequestCanceler
    ): Promise<ChangeDocumentApiResponse> => {
        try {
            const response: ChangeDocumentApiResponse = await this.sitesDataService.value().setDocuments(
                documents.map((item) => item.type),
                siteId,
                cts
            );

            if (!response.is_success) {
                Promise.reject(response.error_code);
            }
            return Promise.resolve(response);
        } catch (err) {
            this.toastApiError(err);
            return Promise.reject(err);
        }
    };

    public editDocument = async (
        params: { chantierId: string; documentId: string },
        document: Document | undefined,
        cts: XHRRequestCanceler
    ): Promise<ChangeDocumentApiResponse> => {
        try {
            const response: ChangeDocumentApiResponse = await this.sitesDataService.value().editDocument(
                params,
                {
                    comment: document?.comment || '',
                    state: document?.state || ('' as DocumentStatus),

                    start_day_date:
                        document && document.start_day_date
                            ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                                day: DateUtils.formatTwoDigitsNumber(
                                    new Date(document.start_day_date)?.getDate()
                                ),
                                month: DateUtils.formatTwoDigitsNumber(
                                    new Date(document.start_day_date)?.getMonth() + 1
                                ),
                                year: new Date(document.start_day_date)?.getFullYear(),
                            })
                            : undefined,
                    end_day_date:
                        document && document.end_day_date
                            ? this.i18nService.value().formatMessage('COMMON_DAY_DATE_FORMAT', {
                                day: DateUtils.formatTwoDigitsNumber(
                                    new Date(document.end_day_date)?.getDate()
                                ),
                                month: DateUtils.formatTwoDigitsNumber(
                                    new Date(document.end_day_date)?.getMonth() + 1
                                ),
                                year: new Date(document.end_day_date)?.getFullYear(),
                            })
                            : undefined,
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getPlannificationEventList = async (
        chantierId: string,
        cts: XHRRequestCanceler
    ): Promise<EventListApiResponse> => {
        try {
            const response: EventListApiResponse = await this.sitesDataService
                .value()
                .getPlannificationEventList(chantierId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    createRdv = async (
        chantierId: string,
        body: CreateOrUpdateRDVServiceData,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const date_time: Date = new Date(body.date);
            date_time.setHours(body.time.getHours());
            date_time.setMinutes(body.time.getMinutes());

            const response: ApiIdDataResponse = await this.sitesDataService.value().createRdv(
                chantierId,
                {
                    comment: body.comment,
                    date_time: date_time.toISOString(),
                    type: body.rdvType.value,
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    getRdv = async (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PlanificationRdvApiResponse> => {
        try {
            const response: PlanificationRdvApiResponse = await this.sitesDataService
                .value()
                .getRdv(chantierId, eventId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    editRdv = async (
        chantierId: string,
        eventId: string,
        body: CreateOrUpdateRDVServiceData,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const date_time: Date = new Date(body.date);
            date_time.setHours(body.time.getHours());
            date_time.setMinutes(body.time.getMinutes());

            const response: BaseApiDataResponse = await this.sitesDataService.value().editRdv(
                chantierId,
                eventId,
                {
                    comment: body.comment,
                    date_time: date_time.toISOString(),
                    type: body.rdvType.value,
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    deleteRdv = async (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PlanificationRdvApiResponse> => {
        try {
            const response: PlanificationRdvApiResponse = await this.sitesDataService
                .value()
                .deleteRdv(chantierId, eventId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    createPhase = async (
        chantierId: string,
        body: EventCreatePhaseState,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const guids = await this.guidsDataService
                .value()
                .getNewGuids(
                    body.compagnonAttribution.length + body.materielAttribution.length,
                    cts
                );

            const response: ApiIdDataResponse = await this.sitesDataService.value().createPhase(
                chantierId,
                {
                    attribution_compagnon_list: body.compagnonAttribution.map((elem, index) => {
                        return {
                            compagnon_id: elem.item.id,
                            end_day_date: elem.endDate
                                ? DateUtils.formatDateAsDayDateString(elem.endDate)
                                : '',
                            id: guids.data[index],
                            start_day_date: elem.startDate
                                ? DateUtils.formatDateAsDayDateString(elem.startDate)
                                : '',
                        };
                    }),
                    attribution_materiel_list: body.materielAttribution.map((elem, index) => {
                        return {
                            materiel_id: elem.item.id,
                            end_day_date: elem.endDate
                                ? DateUtils.formatDateAsDayDateString(elem.endDate)
                                : '',
                            id: guids.data[index + body.compagnonAttribution.length],
                            start_day_date: elem.startDate
                                ? DateUtils.formatDateAsDayDateString(elem.startDate)
                                : '',
                        };
                    }),
                    nature_travaux_phase: body.phase?.phase || '',
                    start_day_date: body.startDate
                        ? DateUtils.formatDateAsDayDateString(body.startDate)
                        : '',
                    end_day_date: body.endDate
                        ? DateUtils.formatDateAsDayDateString(body.endDate)
                        : '',
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    getPhase = async (
        chantierId: string,
        eventId: string,
        cts: XHRRequestCanceler
    ): Promise<PhaseApiDataResponse> => {
        try {
            const response: PhaseApiDataResponse = await this.sitesDataService
                .value()
                .getPhase(chantierId, eventId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    updatePhase = async (
        chantierId: string,
        phaseId: string,
        body: EventCreatePhaseState,
        cts: XHRRequestCanceler
    ): Promise<ApiIdDataResponse> => {
        try {
            const guids = await this.guidsDataService
                .value()
                .getNewGuids(
                    body.compagnonAttribution.length + body.materielAttribution.length,
                    cts
                );

            const response: ApiIdDataResponse = await this.sitesDataService.value().updatePhase(
                chantierId,
                phaseId,
                {
                    attribution_compagnon_list: body.compagnonAttribution.map((elem, index) => {
                        return {
                            compagnon_id: elem.item.id,
                            end_day_date: elem.endDate
                                ? DateUtils.formatDateAsDayDateString(elem.endDate)
                                : '',
                            id: guids.data[index],
                            start_day_date: elem.startDate
                                ? DateUtils.formatDateAsDayDateString(elem.startDate)
                                : '',
                        };
                    }),
                    attribution_materiel_list: body.materielAttribution.map((elem, index) => {
                        return {
                            materiel_id: elem.item.id,
                            end_day_date: elem.endDate
                                ? DateUtils.formatDateAsDayDateString(elem.endDate)
                                : '',
                            id: guids.data[index + body.compagnonAttribution.length],
                            start_day_date: elem.startDate
                                ? DateUtils.formatDateAsDayDateString(elem.startDate)
                                : '',
                        };
                    }),
                    nature_travaux_phase: body.phase?.phase || '',
                    start_day_date: body.startDate
                        ? DateUtils.formatDateAsDayDateString(body.startDate)
                        : '',
                    end_day_date: body.endDate
                        ? DateUtils.formatDateAsDayDateString(body.endDate)
                        : '',
                },
                cts
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    deletePhase = async (
        chantierId: string,
        phaseId: string,
        cts: XHRRequestCanceler
    ): Promise<BaseApiDataResponse> => {
        try {
            const response: BaseApiDataResponse = await this.sitesDataService
                .value()
                .deletePhase(chantierId, phaseId, cts);
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    public getPhaseList = async (
        params: {
            startDate: Date;
            endDate: Date;
        },
        cts: XHRRequestCanceler
    ): Promise<PhaseListApiDataResponse> => {
        try {
            const response: PhaseListApiDataResponse = await this.sitesDataService
                .value()
                .getPhaseList(
                    {
                        start_day_date_before: DateUtils.formatDateAsDayDateString(
                            params.startDate
                        ),
                        end_day_date_after: DateUtils.formatDateAsDayDateString(params.endDate),
                    },
                    cts
                );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

const SitesServiceName = 'SitesService';
export { SitesService, SitesServiceName };
