import './CustomToast.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import className from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import { Icons } from '../Icons/Icons';
import iconError from '../../../assets/icons/ToastIcons/error.svg';
import iconSuccess from '../../../assets/icons/ToastIcons/success.svg';
import iconInfo from '../../../assets/icons/ToastIcons/info.svg';
import { ToastActionOptions } from '../../../../Core/Services/Interfaces/IToastService';

enum CustomToastType {
    ERROR = 'Error',
    SUCCESS = 'Success',
    INFO = 'Info',
}

interface CustomToastProps {
    type: CustomToastType;
    content: React.ReactNode;
    actionOptions?: ToastActionOptions;
}

const CustomToast = ({
    type,
    content,
    actionOptions,
}: CustomToastProps): React.FunctionComponent => {
    const toastClass = className('custom-toast-container', { [type]: type });
    let icon: string;

    switch (type) {
        case 'Error':
            icon = iconError;
            break;
        case 'Success':
            icon = iconSuccess;
            break;
        default:
            icon = iconInfo;
    }

    // const RenderCustomToastAction = (closeToast: () => void): React.ReactNode => {
    //     return (
    //         <div className="customToast-action">
    //             <Button
    //                 title={actionOptions?.actionTitle}
    //                 onClick={() => {
    //                     if (actionOptions && actionOptions.action) {
    //                         actionOptions.action();
    //                     }
    //                     closeToast();
    //                 }}
    //                 variant={ButtonClassType.WithoutBorder}
    //             />
    //         </div>
    //     );
    // };

    const ToastContainer: React.FunctionComponent = ({ closeToast }: any) => {
        return (
            <div className={toastClass}>
                <img src={icon} alt="delete" className="toast-icon" />
                <div className="body">
                    <div>
                        <div className="title">
                            <FormattedMessage id={`Toast_Title${type}`} />
                        </div>
                        <div className="content">{content}</div>
                        {/* {actionOptions && RenderCustomToastAction(closeToast)} */}
                    </div>
                    <IconButton
                        className="close-icon"
                        onClick={closeToast}
                        children={Icons.IconClose}
                    />
                </div>
            </div>
        );
    };

    return ToastContainer;
};

export { CustomToast, CustomToastType };
