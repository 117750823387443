export const ApiUrls = {
    ME: '/api/v1/back/compagnons/me',

    //* Obtient le planning des projets-chantiers + rendez vous.
    PLANNING_CHANTIERS: (startDayDate: string, endDayDate: string): string =>
        `/api/v1/back/plannings/from/${startDayDate}/to/${endDayDate}/chantiers`,

    PLANNING_COMPAGNONS: (startDayDate: string, endDayDate: string): string =>
        `/api/v1/back/plannings/from/${startDayDate}/to/${endDayDate}/compagnons`,
    PLANNING_MATERIELS: (startDayDate: string, endDayDate: string): string =>
        `/api/v1/back/plannings/from/${startDayDate}/to/${endDayDate}/materiels`,

    //* SITES
    SITE_LIST: '/api/v1/back/chantiers',
    SITE_CREATE: '/api/v1/back/chantiers',
    SITE_DETAILS: (id: string) => `/api/v1/back/chantiers/${id}`,
    SITE_EDIT: (id: string) => `/api/v1/back/chantiers/${id}`,

    SITE_DOCUMENT_LIST: (id: string) => `/api/v1/back/chantiers/${id}/documents`,
    SITE_DOCUMENT_EDIT: (siteId: string, documentId: string) =>
        `/api/v1/back/chantiers/${siteId}/documents/${documentId}`,

    SITE_PLANIFICATION_EVENT_LIST: (id: string) => `/api/v1/back/chantiers/${id}/evenements`,
    SITE_PLANIFICATION_CREATE_RDV: (id: string) =>
        `/api/v1/back/chantiers/${id}/evenements/rendez_vous`,
    SITE_PLANIFICATION_GET_RDV: (chantier_id: string, rendez_vous_id: string) =>
        `/api/v1/back/chantiers/${chantier_id}/evenements/rendez_vous/${rendez_vous_id}`,
    SITE_PLANIFICATION_EDIT_RDV: (chantier_id: string, rendez_vous_id: string) =>
        `/api/v1/back/chantiers/${chantier_id}/evenements/rendez_vous/${rendez_vous_id}`,
    SITE_PLANIFICATION_DELETE_RDV: (chantier_id: string, rendez_vous_id: string) =>
        `/api/v1/back/chantiers/${chantier_id}/evenements/rendez_vous/${rendez_vous_id}`,
    SITE_PLANIFICATION_CREATE_PHASE: (id: string) =>
        `/api/v1/back/chantiers/${id}/evenements/phases`,
    SITE_PLANIFICATION_GET_PHASE: (chantierId: string, phaseId: string) =>
        `/api/v1/back/chantiers/${chantierId}/evenements/phases/${phaseId}`,
    SITE_PLANIFICATION_UPDATE_PHASE: (chantierId: string, phaseId: string) =>
        `/api/v1/back/chantiers/${chantierId}/evenements/phases/${phaseId}`,
    SITE_PLANIFICATION_DELETE_PHASE: (chantierId: string, phaseId: string) =>
        `/api/v1/back/chantiers/${chantierId}/evenements/phases/${phaseId}`,
    GET_PHASE_LIST: '/api/v1/back/chantiers/evenements/phases',

    //* USERS
    USERS_LIST: '/api/v1/back/users',
    USERS_DETAILS: (id: string) => `/api/v1/back/users/${id}`,
    USERS_CREATE: '/api/v1/back/users',
    USERS_SEND_INVITATION: (userId: string) =>
        `/api/v1/back/users/${userId}/invitations`,
    USERS_WITH_ATTRIBUTIONS: '/api/v1/back/plannings/compagnons',
    USER_CREATE_ATTRIBUTION: (compagnonId: string) =>
        `/api/v1/back/compagnons/${compagnonId}/attributions`,
    USER_GET_ATTRIBUTION: (compagnonId: string, attributionId: string) =>
        `/api/v1/back/compagnons/${compagnonId}/attributions/${attributionId}`,
    USER_UPDATE_ATTRIBUTION: (compagnonId: string, attributionId: string) =>
        `/api/v1/back/compagnons/${compagnonId}/attributions/${attributionId}`,
    USER_DELETE_ATTRIBUTION: (compagnonId: string, attributionId: string) =>
        `/api/v1/back/compagnons/${compagnonId}/attributions/${attributionId}`,

    //* MATERIEL
    MATERIEL_CREATE: '/api/v1/back/materiels',
    MATERIEL_DETAILS: (materielId: string) => `/api/v1/back/materiels/${materielId}`,
    MATERIEL_UPDATE: (materielId: string) => `/api/v1/back/materiels/${materielId}`,
    MATERIEL_WITH_ATTRIBUTION: '/api/v1/back/plannings/materiels',
    MATERIEL_CREATE_ATTRIBUTION: (materielId: string) => `/api/v1/back/materiels/${materielId}/attributions`,
    MATERIEL_GET_ATTRIBUTION: (materielId: string, attributionId: string) =>
        `/api/v1/back/materiels/${materielId}/attributions/${attributionId}`,
    MATERIEL_DELETE_ATTRIBUTION: (materielId: string, attributionId: string) =>
        `/api/v1/back/materiels/${materielId}/attributions/${attributionId}`,

    //* GUIDS
    GET_GUID_LIST: '/api/v1/back/guids',

    //* NOTIFICATIONS
    GET_NOTIFICATIONS: '/api/v1/back/informations/me',
    PUT_NOTIFICATIONS: '/api/v1/back/informations/me',

    //* ALERTS
    GET_ALERTS: '/api/v1/back/alerts/me',
};
