import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiUrls } from '../Models/ApiUrls';
import { GuidListApiResponse } from '../Models/Guids/Guids';
import { BaseDataService } from './Base/BaseDataService';

export interface IGuidDataService {
    getNewGuids: (count: number, cts: XHRRequestCanceler) => Promise<GuidListApiResponse>;
}

class GuidDataService extends BaseDataService implements IGuidDataService {
    public getNewGuids = (count: number, cts: XHRRequestCanceler): Promise<GuidListApiResponse> => {
        return this.get<GuidListApiResponse>(ApiUrls.GET_GUID_LIST, cts, {
            params: { count: count },
        });
    };
}

const GuidDataServiceName: string = 'GuidDataService';

export { GuidDataService, GuidDataServiceName };
