import { Unsubscribe } from 'redux';

import { AppState } from '../../../../Configs/store/AppReducer';
import { store } from '../../../../Configs/store/store';
import { ErrorHandlerName, IErrorHandler } from '../../../../Libs/xhr/ErrorHandler';
import { Lazy } from '../../../Helpers/Lazy';
import { IToastService, ToastServiceName } from '../../Interfaces/IToastService';
import { IoC } from '../../ServicesContainer';
import { ILogService, getLogService } from '../../PPLusLogService';

export interface IBaseReduxService {
    getState: () => AppState;
    dispatch: <T>(action: T) => T;
    subscribe: (listener: (appStore: AppState) => void) => Unsubscribe;
}

export class BaseReduxService implements IBaseReduxService {
    protected readonly toastService: Lazy<IToastService>;
    private readonly errorHandler: Lazy<IErrorHandler>;
    protected readonly logService: Lazy<ILogService>;

    constructor() {
        this.toastService = IoC.getLazy<IToastService>(ToastServiceName);
        this.errorHandler = IoC.getLazy<IErrorHandler>(ErrorHandlerName);
        this.logService = getLogService();
    }

    public getState = (): AppState => store.getState();

    public dispatch = <T>(action: T): T => store.dispatch(action);

    public subscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public unsubscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public handleApiError = (data: any, disableToast = false) => {
        try {
            this.errorHandler.value()?.handle(data);
        } catch (error) {
            console.log('handleApiError', { error });
        }
    };

    public handleError = (data: any, disableToast = false) => {
        try {
            console.log('handleError : ', { disableToast, data });
            this.errorHandler.value()?.handle(data);
        } catch (error) {
            console.log('handleError', { error });
        }
    };

    public toastApiError = (errCode: string) => {
        this.toastService.value().showError(errCode);
    };
}
