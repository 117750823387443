import './Button.scss';

import React from 'react';
import MuiButton from '@material-ui/core/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Loader } from '../../Templates/Loader/Loader';

enum ButtonClassType {
    Standard = 'Button-standard',
    Danger = 'Button-danger',
    Success = 'Button-success',
    Light = 'Button-light',
    Dark = 'Button-dark',
    Search = 'Button-search',
    Link = 'Button-link',
}

export interface ButtonProps {
    icon?: React.ReactElement;
    endRowIcon?: React.ReactElement;
    title?: React.ReactNode;
    variant: ButtonClassType;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    isBusy?: boolean;
    link?: string;
    visible?: boolean;
    fullWidth?: boolean;
}

const Button = ({
    title = '',
    icon,
    endRowIcon,
    variant,
    className,
    onClick,
    disabled = false,
    type,
    isBusy,
    link,
    visible = true,
    fullWidth,
}: ButtonProps) => {
    const getContent = (): React.ReactNode => (
        <div
            className={classNames({
                [variant]: variant,
            })}
        >
            <div
                className={classNames({
                    'Button-content': true,
                    'Button-busy': isBusy,
                    'Button-fullWidth': fullWidth,
                })}
            >
                {icon && !isBusy && <div className="Button-icon">{icon}</div>}
                {isBusy ? <Loader /> : title}
                {endRowIcon && !isBusy && <div className="Button-endRowIcon">{endRowIcon}</div>}
            </div>
        </div>
    );

    if (!visible) {
        return null;
    }

    return (
        <div
            className={classNames({
                [className as string]: className,
                'Button-root': true,
                'Button-disabled': disabled,
            })}
        >
            <MuiButton onClick={onClick} disabled={disabled} type={type}>
                {link ? <Link to={link}>{getContent()}</Link> : getContent()}
            </MuiButton>
        </div>
    );
};

export { Button, ButtonClassType };
