import { XHRRequestCanceler } from '../../Libs/xhr/XHRRequestCanceler';
import { ApiUrls } from '../Models/ApiUrls';
import { GetPaginatedQueryParameter } from '../Models/Commons/PaginatedQuery';
import { AlertsListResponse } from '../Models/Notifications/Alerts';
import { BaseDataService } from './Base/BaseDataService';

export interface IAlertsDataService {
    getAlerts: (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ) => Promise<AlertsListResponse>;
}

class AlertsDataService extends BaseDataService implements IAlertsDataService {
    public getAlerts = (
        params: GetPaginatedQueryParameter,
        cts: XHRRequestCanceler
    ): Promise<AlertsListResponse> => {
        return this.get<AlertsListResponse>(ApiUrls.GET_ALERTS, cts, { params });
    };
}

const AlertsDataServiceName: string = 'AlertsDataService';

export { AlertsDataService, AlertsDataServiceName };
