//🇫🇷
export const fr = {
    ApplicationName: 'PPlus',

    //* COMMONS
    COMMON_YES: 'Oui',
    COMMON_NO: 'Non',
    COMMON_REQUIRED_FIELD: 'Champ obligatoire*',
    COMMON_EMPTY_MESSAGE: 'Aucun résultat',
    COMMON_ADD: 'Ajouter',
    COMMON_CHANGE: 'Modifier',
    COMMON_BAD_VALUE_FIELD: 'Champ erroné',
    COMMON_EXPORT: 'Exporter',
    COMMON_IMPORT: 'Parcourir',
    COMMON_SAVE: 'Enregistrer',
    COMMON_CANCEL: 'Annuler',
    COMMON_VALIDATE: 'Valider',
    COMMON_DELETE: 'Supprimer',
    COMMON_SEARCH: 'Recherche',
    COMMON_SEARCH_TITLE: 'Rechercher',
    COMMON_PLACEHOLDER: '_',
    COMMON_ENABLED: 'Actif',
    COMMON_DISABLED: 'Inactif',
    COMMON_SAVE_SUCCESS: 'Enregistrement réussi !',
    COMMON_DAY_DATE_FORMAT: `{year}-{month}-{day}`,
    COMMON_INVALID_EMAIL: "Format de l'adresse email invalide",
    COMMON_DATE_FORMAT: 'YYYY-MM-dd',
    COMMON_TODAY: "Aujourd'hui",
    COMMON_YESTERDAY: 'Hier',

    //* Navigation Header
    Header_Logout: 'Se déconnecter',

    //* Navigation Menu
    Navbar_Planning: 'Planning',
    Navbar_Sites: 'Chantiers',
    Navbar_Compagons: 'Équipe',
    Navbar_Equipments: 'Matériels',
    Navbar_Users: 'Admins',

    User_FullName: '{firstName} {lastName}',

    Login_Login: 'Se connecter avec office 365',

    //* DIALOG
    Dialog_LogoutTitle: 'Déconnexion',
    Dialog_LogoutMessage: 'Êtes-vous sur de vouloir vous déconnecter ?',

    //* TOAST
    Toast_GenericSuccessUpdateData: 'Les données ont été mises à jour',
    Toast_TitleSuccess: 'Succès!',
    Toast_TitleError: 'Erreur!',
    Toast_TitleInfo: 'Info!',

    //* ERRORS
    Error_GenericError: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer",
    Error_RequiredFieldsCannotBeEmpty:
        'Pour enregistrer votre saisie, tous les champs obligatoires doivent être saisis.',
    Error_FORBIDDEN: "Vous n'êtes pas authorisé à accéder au backoffice",
    Error_EMAIL_ALREADY_REGISTERED: 'Cet email est déjà utilisé',
    Error_INVALID_EMAIL_FORMAT: "Format de l'adresse email invalide",
    Error_START_DATE_END_DATE_SPAN_INCONSISTENT:
        "Les dates d'une phase doivent être comprises dans les dates de début et de fin du chantier",
    Error_INCONSISTENT_DATE_SPAN_RELATIONSHIPS: 'Les dates ne sont pas correctes',
    Error_DOCUMENT_ALREADY_CREATED: 'Ce document est déja créé',
    Error_START_DATE_END_DATE_ARPDRE_SITE:
        "Le délai de 31 jours entre la réception de l'AR PDRE et le début du chantier n'est pas respecté",

    //* USERS
    Users_FirstAndLastName: '{firstName} {lastName}',
    Users_LastAndFirstName: '{lastName} {firstName}',
    Users_FirstLetterAndLastName: '{firstLetter}.{lastName}',

    //* Select
    Select_Placeholder: '-',

    //* DIALOG
    Dialog_ConfirmTitle: 'Attention',
    Dialog_ConfirmMessage:
        'Voulez-vous vraiment quitter ? Toutes vos modifications seront perdues.',
    Dialog_ConfirmCancel: 'Annuler',
    Dialog_ConfirmQuit: 'Quitter sans enregistrer',

    Dialog_MessageDeleteEvent:
        "Voulez-vous vraiment supprimer l'événement ? Cette action est irréversible.",
    Dialog_ConfirmDeleteEvent: "Supprimer l'évenement",

    //* PLANNING
    Planning_Title: 'Planning',
    Planning_WeeksFormat: 'S{firstWeek}-{lastWeek}',
    Planning_MounthFormat: '{Month} {year}',

    PopupPlanningRDV_TitleAppointmentOfTheDay: 'RDV DU JOUR',
    PopupPlanningRDV_TitleSiteDetails: 'DÉTAILS DU CHANTIER',

    PopupPlanningRDV_TimeFormat: '{hour}h',
    PopupPlanningRDV_DateFormat: '{day}/{month}/{year}',
    PopupPlanningRDV_LinkFormat: '{number} - {name}',
    PopupPlanningRDV_Empty_Message: 'Pas de RDV',

    PlanningTwoWeek_WeekFormat: 'Semaine {weekNumber} - {month}',

    //* SITES
    SiteList_Title: 'Chantiers',
    SiteCreate_Title: 'Nouveau chantier',
    SIteDetails_Title: '{code} - {libelle}',

    //* COMPAGNONS
    CompagnonList_Title: 'Équipe',
    PlanningCompagnon_Header_Name: 'COMPAGNONS',
    PlanningCompagnon_Header_Status: 'STATUS',
    PlanningCompagnon_Attribution_Formation: 'Formation',
    PlanningCompagnon_Attribution_Conges: 'CP',

    //* EQUIPMENTS
    EquipmentList_Title: 'Matériels',
    EquipmentCreate_Title: 'Ajouter un matériel',
    EquipmentDetails_title: 'Modifier un matériel',
    EquipmentCreate_Success: 'Le matériel a été créee avec success',

    PlanningEquipment_Header_Name: 'NOM',
    PlanningEquipment_Header_Type: 'TYPE',
    PlanningEquipment_Attribution_Maintenance: 'MAINT',
    PlanningEquipment_Materiel_Type_Demolition: 'Démolition',
    PlanningEquipment_Materiel_Type_Desamiantage: 'Désamiantage',
    PlanningEquipment_Materiel_Type_Terrassement: 'Terrassement',

    PlanningEquipment_AddEquipment: 'Ajouter un matériel',

    EquipmentInformations_Name: 'Nom* :',
    EquipmentInformations_State: 'État* :',

    EquipmentInformations_Required_Name: 'Le nom du matériel est est obligatoire',
    EquipmentInformations_Required_Type: 'La nature du travaux est obligatoire',

    //* USERS
    UserList_Title: 'Admins',
    UserCreate_Title: 'Ajouter un utilisateur',
    UserModify_title: 'Modifier un utilisateur',

    //* FILTERS
    Filters_All: 'Voir tous',
    Desamiantage: 'Désamiantage',
    Demolition: 'Démolition',
    Terrassement: 'Terrassement',

    //* DATE PICKER
    DatePicker_CancelLabel: 'ANNULER',
    DatePicker_DateFormat: 'dd / MM / yyyy',

    //* TIME PICKER
    TimePicker_CancelLabel: 'ANNULER',
    TimePicker_TimeFormat: "HH 'h' MM",
    TimePicker_TimeFormatCompressed: "HH'h'mm",

    //* AuthPagesLayout
    AuthPagesLayout_Title: "Bienvenue sur l'espace",
    AuthPagesLayout_AppName: 'PLANNI +',

    //* LOGIN
    Login_EmailPlaceholder: 'Email',
    Login_PasswordPlaceholder: 'Mot de passe',
    Login_ForgotButtonTitle: 'Mot de passe oublié ?',
    Login_SubmitButtonTitle: 'Se connecter',
    Login_InvalidCredentials:
        'Les identifiants renseignés semblent être erronés.Veuillez réessayer ou réinitialisez votre mot de passe via le lien « Mot de passe oublié ?',
    Login_EmailNotRecognized: 'Email non reconnu',
    Login_PasswordNotRecognized: 'Mot de passe non reconnu',

    //* FORGOT PASSWORD
    ForgotPassword_HelpText:
        'Merci de renseigner votre adresse mail afin de réinitialiser votre mot de passe.',
    ForgotPassword_EmailPlaceholder: 'Email',
    ForgotPassword_SubmitButtonTitle: 'Réinitialiser mon mot de passe',
    ForgotPassword_BackButtonTitle: '< Retour à la connexion',
    ForgotPassword_MessageSuccess: 'Un email vous a été envoyé',

    //* RESET PASSWORD
    ResetPassword_HelpText:
        'Suite à votre demande de réinitialisation de mot de passe, merci de renseigner ici un nouveau mot de passe et de le confirmer.',
    ResetPassword_EmailPlaceholder: 'Email',
    ResetPassword_SubmitButtonTitle: 'Envoyer',

    //* NEW PASSWORD
    NewPassword_HelpText: 'Merci de saisir votre nouveau mot de passe.',
    NewPassword_PasswordPlaceholder: 'Nouveau mot de passe',
    NewPassword_PasswordConfirmPlaceholder: 'Confirmer le nouveau mot de passe',
    NewPassword_SubmitButtonTitle: 'Valider',
    NewPassword_BackButtonTitle: '< Retour à la connexion',
    NewPassword_NotMatching: 'Les deux mots de passe ne sont pas identiques.',
    NewPassword_Rules:
        "Le format de votre mot de passe n'est pas valide. Il doit comporter au moins 8 caractères, une majuscule et un caractère spécial.",

    //* SITE LIST
    SiteList_SearchPlaceholder: 'Recherche',
    SiteList_AddSiteButtonTitle: 'Ajouter un chantier',
    SiteList_ProjectTitle: 'Projet',
    SiteList_ColumnProjectOrNumber: 'PROJET / N° DE CHANTIER',
    SiteList_ColumnLibelle: 'LIBELLÉ',
    SiteList_ColumnVille: 'VILLE',

    //* SITE INFORMATIONS
    SiteInformations_InfoSectionTitle: 'Informations générales',
    SiteInformations_SiteInfo: 'INFOS DE CHANTIER',
    SiteInformations_Libelle: 'Libellé* :',
    SiteInformations_StartDate: 'Début* :',
    SiteInformations_EndDate: 'Fin* :',
    SiteInformations_Number: '# N° :',
    SiteInformations_Town: 'Ville :',
    SiteInformations_Address: 'Adresse :',
    SiteInformations_Name: 'Nom :',
    SiteInformations_Phone: 'Tél :',
    SiteInformations_Mail: 'E-mail :',
    SiteInformations_Cancel: 'Annuler',
    SiteInformations_Validate: 'Valider',
    SiteInformations_EntrepriseMandataire: 'ENTREPRISE MANDATAIRE',
    SiteInformations_Laboratory: 'LABORATOIRE',
    SiteInformations_MaitreOuvrage: "MAÎTRE D'OUVRAGE",
    SiteInformations_Comment: 'COMMENTAIRES',
    SiteInformations_Coordinateur: 'COORDINATEUR SPS',
    SiteInformations_VisualController: 'CONTRÔLEUR VISUEL',
    SiteInformations_ElecController: 'CONTRÔLEUR ÉLÈC ',
    SiteInformations_Moe: 'MOE',
    SiteInformations_Huissier: 'HUISSIER',
    SiteInformations_Other: 'AUTRE',
    SiteInformations_ContactExternesSectionTitle: 'Contacts externes',
    SiteInformations_ContactsInternesSectionTitle: 'Contacts internes',
    SiteInformations_ContactsInternesSectionSubTitle:
        'Définir la liste des utilisateurs concernés par ce chantier.',
    SiteInformations_NatureTravauxEmpty: 'Nature de travaux*',
    SiteInformations_LaboratoriesEmpty: 'Laboratoire',
    SiteInformations_ContactInternesSelectButtonTitle: 'Voir / Modifier la liste',
    SiteInformations_SuccesCreate: 'Chantier créé avec succès !',

    //* WORK TYPE
    WorkType_Desamiantage: 'Désamiantage',
    WorkType_Demolition: 'Démolition',
    WorkType_Terrassement: 'Terrassement',

    //* SITE TYPE
    SiteType_Project: 'Projet',
    SiteType_Chantier: 'Chantier',

    //* SITE DETAILS
    SiteDetails_InfoTabTitle: 'Informations',
    SiteDetails_DocumentTabTitle: 'Documents',
    SiteDetails_PlanningTabTitle: 'Planification',

    //* LABORATORIES
    DiagConsult: 'DIAG CONSULT',
    Helfy: 'HELFY',
    MetaEtPrelevement: 'META & PRELEVEMENT',
    ITGA: 'ITGA',
    EXIM: 'EXIM',

    //* USER LIST
    UserList_FirstNameLastNameTitle: 'NOM PRÉNOM',
    UserList_EmailTitle: 'E-MAIL',
    UserList_TypeTitle: 'TYPE',
    UserList_StateTitle: 'ÉTAT',
    UserList_AddUserButtonTitle: 'Ajouter un admin',

    //* USER INFORMATIONS
    UserInformations_Encadrement: 'Encadrement',
    UserInformations_Compagnon: 'Compagnon',
    UserInformations_FirstNameLabel: 'Prénom* :',
    UserInformations_LastNameLabel: 'Nom* :',
    UserInformations_EmailLabel: 'E-mail* :',
    UserInformations_StateLabel: 'État* :',
    UserInformations_SendMailButtonTitle: "Envoyer l'invitation",
    UserInformations_ResetPasswordButtonTitle: 'Réinitialiser mon mot de passe',
    UserInformations_StatusPEC: 'PEC',
    UserInformations_StatusPOC: 'POC',
    UserInformations_StatusTitle: 'Statut* :',
    UserInformations_SendMailConfirmTitle: "Envoyer un email d'invitation",
    UserInformations_SendMailConfirmMessage:
        "Voulez-vous vraiment envoyer un email d'invitation ? ",
    UserInformations_SendMailConfirmValid: 'Envoyer l’invitation',
    UserInformations_SuccesCreate: 'Utilisateur créé avec succès !',

    //* SITE DOCUMENTS
    SiteDocuments_AddSiteButtonTitle: 'Liste des documents',
    SiteDocuments_StartDateTitle: 'Début:',
    SiteDocuments_EndDateTitle: 'Fin:',
    SiteDocuments_DateFormat: 'dd/MM/YYYY',
    SiteDocuments_EmptyTitle: 'Ajouter un document',
    SiteDocuments_EmptyText: 'Tapper ou cliquer sur le bouton « Liste des documents »',
    SiteDocuments_CommentaireTitle: 'Commentaire',

    SiteDocuments_State_Fait: 'Fait',
    SiteDocuments_State_EnCours: 'En cours',
    SiteDocuments_State_NonFait: 'Non fait',

    SiteDocuments_Title_ArPDRE: 'AR PDRE',
    SiteDocuments_Title_ArreteDeVoirie: 'Arrêté de voirie',
    SiteDocuments_Title_BonDeCommande: 'Bon de commande',
    SiteDocuments_Title_Bsd: 'BSD',
    SiteDocuments_Title_Bsda: 'BSDA',
    SiteDocuments_Title_Cap: 'CAP',
    SiteDocuments_Title_ConsignationDeResaux: 'Consignation de réseaux',
    SiteDocuments_Title_ConstatHuissier: "Constat d'huissier",
    SiteDocuments_Title_CourrierDeDecalageDeDateIT: 'Courier de décalage de date IT',
    SiteDocuments_Title_CRVisite: 'CR visite',
    SiteDocuments_Title_DelcarationGlobale: 'Déclaration globale',
    SiteDocuments_Title_DictAdductionEau: "DICT - Adduction d'eau",
    SiteDocuments_Title_DictAutre: 'DICT - Autre',
    SiteDocuments_Title_DictEclairage: 'DICT - Éclairage',
    SiteDocuments_Title_DictFibre: 'DICT - Fibre',
    SiteDocuments_Title_DictTelephonie: 'DICT - Téléphonie',
    SiteDocuments_Title_DictTrapil: 'DICT - Trapil',
    SiteDocuments_Title_DictElectricite: 'DICT – Électricité',
    SiteDocuments_Title_DictGaz: 'DICT – Gaz',
    SiteDocuments_Title_Fip: 'FIP',
    SiteDocuments_Title_Pdre: 'PDRE',
    SiteDocuments_Title_PointZero: 'Point zéro',
    SiteDocuments_Title_Ppsps: 'PPSPS',
    SiteDocuments_Title_StrategieEchantillonage: "Stratégie d'échantillonnage",
    SiteDocuments_Title_Autre: 'Autre',

    //* SITE PLANIFICATION
    SitePlanification_AddEventButtonTitle: 'Ajouter un événement',
    SitePlanification_DateFormat: 'dd/MM/YYYY',
    SitePlanification_HourFormat: '{hours}h{minutes}',
    SitePlanification_MoreItems: '+{number}',
    SitePlanification_EmptyTitle: 'Ajouter un événement',
    SitePlanification_EmptyText: 'Tapper ou cliquer sur le bouton « Ajouter un événement »',

    PlanificationInformations_CreateEventTitle: 'Ajouter un évenement',
    PlanificationInformations_EditEventTitle: 'Modifier un évenement',
    PlanificationInformations_Rdv: 'Rendez-vous ponctuel',
    PlanificationInformations_Phase: 'Phase de production',
    PlanificationInformations_RdvType: 'Type de rendez-vous',
    PlanificationInformations_DatePickerTitle: 'Date* :',
    PlanificationInformations_TimePickerTitle: 'Heure* :',
    PlanificationInformations_RdvSelectLabel: 'Types de RDV*',
    PlanificationInformations_CommentLabel: 'Commentaires',
    Dialog_ConfirmDeleteEventTitle: 'Supprimer',
    Dialog_ConfirmDeleteEventValidate: "Supprimer l'évenement",
    Dialog_ConfirmDeleteEventText:
        'Voulez-vous vraiment supprimer l’événement ? Cette action est irrévocable.',
    PlanificationInformations_ChantierLabel: 'Chantier *',
    EventCreatePhase_SelectPhaseTypeEmptyTitle: 'SÉLECTIONNER UNE PHASE*',
    EventCreatePhase_SelectChantierEmptyTitle: 'SÉLECTIONNER UNE CHANTIER*',
    EventCreatePhase_SelectChantierTitle: 'Liste des chantiers',
    PlanificationInformations_AddCompagnonSectionLabel: 'Compagnons',
    PlanificationInformations_AddMaterielSectionLabel: 'Matériels',
    PlanificationInformations_AddCompagnonButtonLabel: 'Ajouter un compagnon',
    PlanificationInformations_AddMaterielButtonLabel: 'Ajouter un matériel',
    PlanificationInformations_SiteCodeAndTitle: '{code} {libelle}',
    PlanificationInformations_StartDatePickerTitle: 'Début* :',
    PlanificationInformations_EndDatePickerTitle: 'Fin* :',
    PlanificationInformations_AttributedCompagnonTitle: `{firstName} {lastName} - {siteName} {siteCode}`,
    PlanificationInformations_AttributedMaterielTitle: `{title} - {siteName} {siteCode}`,

    //* PHASE NAME
    PhaseName_DesamiantageConfinement: 'Confinement',
    PhaseName_DesamiantageDepose: 'Dépose',
    PhaseName_DesamiantageRepli: 'Repli',
    PhaseName_DesamiantagePlomb: 'Plomb',
    PhaseName_DemolitionCurage: 'Curage',
    PhaseName_Demolition: 'Démolition',
    PhaseName_DemolitionSciage: 'Sciage',
    PhaseName_Terrassement: 'Terrassement',
    PhaseName_TerrassementDepollution: 'Dépollution',

    //* RDV NAME
    RdvName_VisitePrelable: 'Visite préalable',
    RdvName_InspectionCommune: 'Inspection commune',
    RdvName_ConstatHuissier: "Constat d'huissier",
    RdvName_PointZero: 'Point 0',
    RdvName_ReunionChantier: 'Réunion de chantier',
    RdvName_PremierRestitution: 'Suivi environnemental',
    RdvName_MetaOperateur: 'Méta opérateur',
    RdvName_FinDeChantier: 'Fin de chantier',
    RdvName_DemarrageChantier: 'Démarrage chantier',
    RdvName_VerificationAerolique: 'Vérification aéraulique',
    RdvName_VerificationElectrique: 'Verification électrique',
    RdvName_ExamenVisuel1: 'Examen visuel 1',
    RdvName_ExamenVisuel2: 'Examen visuel 2',
    RdvName_AuditInterne: 'Audit interne',
    RdvName_EssaiDePlaque: 'Essai de plaque',
    RdvName_Liberatoire: 'Libératoire',
    RdvName_Autre: 'Autre',

    //* COMPAGNON ATTRIBUTION
    CompagnonAttribution_PageTitle: 'Ajouter un événement',
    CompagnonAttribution_CustomRadioProductionTitle: 'Production',
    CompagnonAttribution_CustomRadioCongesTitle: 'Congés',
    CompagnonAttribution_CustomRadioFormationTitle: 'Formation',
    CompagnonAttribution_StartDatePickerTitle: 'Début :',
    CompagnonAttribution_EndDatePickerTitle: 'Fin :',
    CompagnonAttribution_ChantierLabel: 'Chantier *',
    CompagnonAttribution_CompagnonLabel: 'Compagnon',
    CompagnonAttribution_SelectChantierEmptyTitle: 'Sélectionner un chantier',
    CompagnonAttribution_Autre: 'AUTRE',
    CompagnonAttribution_SelectChantierFormat: '{code} {name} - {phase}',

    //* EQUIPMENT ATTRIBUTION
    Attribution_PageTitle: 'Ajouter un événement',
    Attribution_ProductionTitle: 'Production',
    Attribution_MaintenanceTitle: 'Maintenance',
    Attribution_StartDatePickerTitle: 'Début :',
    Attribution_EndDatePickerTitle: 'Fin :',
    Attribution_ChantierLabel: 'Chantier *',
    Attribution_EquipmentLabel: 'Materiel',
    Attribution_SelectChantierEmptyTitle: 'Sélectionner un chantier',
    Attribution_SelectPhaseProductionTitle: 'Phase de production',
    Attribution_ProductionTitleFormat: 'Prod - {date}',
    Attribution_ProductionDateFormat: 'dd MMMM yyyy',
    Attribution_Autre: 'AUTRE',
    Attribution_Maintenance_CommentLabel: 'Commentaire',

    //* NOTIFICATIONS
    Notifications_InformationsPopoverTitle: 'INFO',
    Notifications_AlertsPopoverTitle: 'ALERTES',
    Notifications_FooterButtonTitle: 'Voir plus',
    Notifications_Date_Format: 'dd-MM-YYYY',
    Notifications_Title: '{number} {name}',
    Notifications_Default_Description: 'La date de démarrage a été modifiée',
};
