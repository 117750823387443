import { XHRRequestCanceler } from '../../../../Libs/xhr/XHRRequestCanceler';
import { IMeDataService, MeDataServiceName } from '../../../DataServices/MeDataService';
import { Lazy } from '../../../Helpers/Lazy';
import { MeResponse } from '../../../Models/Me/Me';
import { IoC } from '../../ServicesContainer';
import { BaseReduxService } from '../Api/BaseReduxService';
import { MeActions } from './MeActions';

export interface IMeService {
    getMyInfo: (cts?: XHRRequestCanceler) => Promise<MeResponse | any>;
}

class MeService extends BaseReduxService implements IMeService {
    private meDataService: Lazy<IMeDataService>;

    constructor() {
        super();
        this.meDataService = IoC.getLazy<IMeDataService>(MeDataServiceName);
    }

    public getMyInfo = async (cts?: XHRRequestCanceler): Promise<MeResponse | any> => {

        try {
            await this.dispatch(MeActions.setInitialized(false));
            const response: MeResponse = await this.meDataService.value().getMyInfo(cts);

            await this.dispatch(MeActions.setInfo(response.data));
            await this.dispatch(MeActions.setInitialized(true));

            return Promise.resolve(response);
        } catch (error) {
            await this.dispatch(MeActions.setInfo());
            await this.dispatch(MeActions.setInitialized(true));
            return Promise.reject(error);
        }
    };
}

const MeServiceName = 'MeService';
export { MeService, MeServiceName };
