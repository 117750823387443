import './logo.scss';

import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import logo from '../../../assets/logos/logo.svg';
import logoNavbar from '../../../assets/logos/logo_navbar.svg';

enum LogoType {
    Default,
    Login,
    Navbar,
}

export interface LogoProps {
    className?: string;
    style?: {};
    large?: boolean;
    //* Applies the 'cursor: pointer;' style if truthy
    onClick?: () => void;
    //* Display only the icon without the text */
    icon?: boolean;
    type?: LogoType;
}

const getLogoImage = (logoType: LogoType) => {
    if (logoType === LogoType.Navbar) {
        return { image: logoNavbar, imageClassName: 'logo-navbar ' };
    }
    return { image: logo, imageClassName: '' };
};

const Logo: FunctionComponent<LogoProps> = ({
    type = LogoType.Default,
    className: customClassName = '',
    style,
    large = false,
    onClick,
    icon = false,
    ...rest
}: LogoProps) => {
    const { image, imageClassName } = getLogoImage(type);

    const className = classnames({
        'app-logo': true,
        [imageClassName]: imageClassName,
        large,
        clickable: !!onClick,
        [customClassName]: customClassName,
    });

    return (
        <img
            alt="app logo"
            src={image}
            className={className}
            style={style}
            onClick={onClick}
            {...rest}
        />
    );
};

export { Logo, LogoType };
